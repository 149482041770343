import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
import store from "../store";
import { toast } from "react-toastify";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const base_url_2 = "https://digi.pmpframe.com/"

export const get_video = createAsyncThunk("video", async () => {
  const body = new FormData();
  body.append("action", "list");
//   body.append("userid", user.userid);
  const response = await fetch(base_url + "api/video.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const delete_video = createAsyncThunk("delvideo", async (data) => {
    const body = new FormData();
    body.append("action", "delete");
    body.append("id",data?.id);
    const response = await fetch(base_url + "api/video.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  });

const videoSlice = createSlice({
  name: "video",
  initialState: {
    video_list: [],
    video_loading: false,
    delete_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(get_video.pending, (state, action) => {
      state.video_loading = true;
    });
    builder.addCase(get_video.rejected, (state, action) => {
      state.video_loading = false;
    });
    builder.addCase(delete_video.pending, (state, action) => {
      state.delete_loading = true;
    });
    builder.addCase(delete_video.rejected, (state, action) => {
      state.delete_loading = false;
    });
    builder.addCase(delete_video.fulfilled, (state, action) => {
      state.delete_loading = false;
      get_video()
      //   store.dispatch(getClaims());
    });

    builder.addCase(get_video.fulfilled, (state, action) => {
      const data = action.payload;
      state.video_loading = false;
      if (data.success) {
        state.video_list = data.data;
      } else {
        state.video_list = [];
      }
    });
  },
});

export default videoSlice.reducer;
