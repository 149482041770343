import {
  Box,
  Container,
  Flex,
  Heading,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";
import { useSelector } from "react-redux";

const Layout2 = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { background_list,  } = useSelector(
    (state) => state.background
  );

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Background Image List</Heading>
        <CustomButton
          title={"Add Images"}
          onClick={() =>
            navigate("/add-img", {
              state:
                pathname == "/bg-image/app"
                  ? "app"
                  : pathname == "/bg-image"
                  ? "web"
                  : "",
            })
          }
        />
      </Flex>
      <Flex gap={2} align={"center"}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Background Image: {background_list?.length}
        </Text>
      </Flex>
      <Flex gap={2} mt={2}>
        <CustomButton
          bg={pathname == "/bg-image" ? "" : "gray"}
          onClick={() => navigate("/bg-image")}
          title={"WEB"}
        />
        <CustomButton
          bg={pathname == "/bg-image/app" ? "" : "gray"}
          onClick={() => navigate("/bg-image/app")}
          title={"APP"}
        />
      </Flex>
      <Spacer h={3} />
      <VStack w={"full"} overflowY={"scroll"} py={3} bg={"#FAFBFE"}>
        <Outlet />
      </VStack>
    </Container>
  );
};

export default Layout2;
