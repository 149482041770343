import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import store from "../../redux/store";
import { getMessages, getSearchMessages } from "../../redux/slice/messageSlice";
import CustomPopup from "../../components/CustomPopup";
import CustomInput from "../../components/CustomInput";
import TableHeader from "../../components/TableHeader";
import { message_action } from "../../utils/apis";
import { FiEdit3, FiEye } from "react-icons/fi";
import CustomToolTip from "../../components/CustomTooltip";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { base_url, exportPDF } from "../../utils/utils";

const Messages = () => {
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const [assignedData, setAssignedData] = useState([]);
  const navigate = useNavigate();
  const [initialValue, setInitialValue] = useState(1);
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const { message_list, loading, total_message, total_message_list } =
    useSelector((state) => state.messages);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mess_data, setMess_data] = useState([]);
  const {
    isOpen: isCOpen,
    onOpen: onCOpen,
    onClose: onCClose,
  } = useDisclosure();
  const {
    isOpen: isCOpen1,
    onOpen: onCOpen1,
    onClose: onCClose1,
  } = useDisclosure();
  const [data, setData] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [text, setText] = useState("");
  const [id, setId] = useState("");
  const [value, setValue] = useState("All");
  const [search, setSearch] = useState("");
  const [edit_assign_id, setEdit_assign_id] = useState("");
  const getAssigned_data = async () => {
    const body = new FormData();
    body.append("action", "assignedto");
    body.append("userid", user.userid);

    const response = await fetch(base_url + "api/user-policies.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    if (res.success) {
      setAssignedData(res.message);
    } else {
      setAssignedData([]);
    }
  };
  useEffect(() => {
    getAssigned_data();
    setMess_data(message_list);
    store
      .dispatch(getMessages({ rows: rows, page: page }))
      .then(unwrapResult)
      .then((v) =>
        setMess_data(v.message == "No Data available" ? [] : v.message)
      );
  }, []);
  const update = () => {
    onCClose();
    const body = new FormData();
    body.append("policy", "comment");
    body.append("id", id);
    body.append("comment", text);
    message_action(body, setLoading1).then((v) => {
      store
        .dispatch(getMessages({ rows: rows, page: page }))
        .then(unwrapResult)
        .then((v) =>
          setMess_data(v.message == "No Data available" ? [] : v.message)
        );
    });
  };
  const update_status = (id, v) => {
    const body = new FormData();
    body.append("policy", "status");
    body.append("id", id);
    body.append("status", v.toString());
    message_action(body, setLoading1).then((v) => {
      store
        .dispatch(getMessages({ rows: rows, page: page }))
        .then(unwrapResult)
        .then((v) =>
          setMess_data(v.message == "No Data available" ? [] : v.message)
        );
    });
  };
  const update_assigned = (id, text) => {
    if (text.length == 0) {
      toast.info("Please enter assigned to");
      return;
    }
    const body = new FormData();
    body.append("policy", "assigned");
    body.append("id", id);
    body.append("assigned", text);
    message_action(body, setLoading1).then((v) => {
      setEdit_assign_id("");
      store
        .dispatch(getMessages({ rows: rows, page: page }))
        .then(unwrapResult)
        .then((v) =>
          setMess_data(v.message == "No Data available" ? [] : v.message)
        );
    });
  };
  const table_data = total_message_list.map((elt, i) => [
    i + 1,
    elt.name,
    elt.email,
    elt.contact,
    elt.status == 0 ? "InProcess" : elt.status,
    elt.assigned,
    elt.comment,
  ]);
  const export_pdf = () => {
    exportPDF({
      table_data: table_data,
      table_headers: [
        "S.No.",
        "Name",
        "Email",
        "Contact",
        "Status",
        "Assigned",
        "Comment",
      ],

      fileName: "message-list",
      tableName: "Insugo Message List",
    });
  };
  const headers = [
    { label: "S.No.", key: "no" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Contact", key: "mobile" },
    { label: "Status", key: "status" },
    { label: "Assigned", key: "assigned" },
    { label: "Comment", key: "comment" },
  ];
  const CSVdata = total_message_list.map((elt, i) => ({
    no: i + 1,
    name: elt.name,
    email: elt.email,
    mobile: elt.contact,
    status: elt.status == 0 ? "InProcess" : elt.status,
    assigned: elt.assigned,
    comment: elt.comment,
  }));

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Heading fontSize={"xl"}>Message List</Heading>
      <Spacer h={3} />
      <Flex gap={2} align={"center"} mb={1}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Messages:
        </Text>
        <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
          {total_message}
        </Text>
      </Flex>

      <TableHeader
        CSVdata={CSVdata}
        headers={headers}
        filename={"message-list.csv"}
        onPdfClick={() => export_pdf()}
        data={["All", "Name", "Email", "Mobile", "Status"]}
        onChange={(v) => setValue(v.target.value)}
        value={search}
        onSerchChange={(v) => {
          setRows(5);
          setpage(1);
          v.target.value.length <= 2 &&
            store
              .dispatch(
                getMessages({
                  page: page,
                  rows: rows,
                })
              )
              .then(unwrapResult)
              .then((v) =>
                setMess_data(v.message == "No Data available" ? [] : v.message)
              );
          v.target.value.length > 2 &&
            store
              .dispatch(
                getSearchMessages({
                  page: page,
                  rows: rows,
                  value: value,
                  v: v.target.value,
                })
              )
              .then(unwrapResult)
              .then((v) =>
                setMess_data(v.message == "No Data available" ? [] : v.message)
              );

          setSearch(v.target.value);
        }}
      />
      {loading && message_list.length === 0 ? (
        <Loader />
      ) : message_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "Name",
            "Enquiry Type",
            "Email",
            "Contact",
            "Status",
            "Assigned to",
            "Comment",
          ]}
          body={mess_data?.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + initialValue}</Td>
              <Td
                textAlign={"center"}
                cursor={"pointer"}
                onClick={() => {
                  setData(v);
                  onOpen();
                }}
              >
                {v.name}
              </Td>
              <Td textAlign={"center"}>{v.insurance_type}</Td>
              <Td textAlign={"center"}>{v.email}</Td>
              <Td textAlign={"center"}>{v.contact}</Td>

              <Td textAlign={"center"} w={"36"}>
                <Select
                  w={"32"}
                  size={"sm"}
                  borderRadius={"md"}
                  value={v.status == 0 ? "InProcess" : v.status}
                  onChange={(e) => update_status(v.id, e.target.value)}
                >
                  <option value="InProcess">In Process</option>
                  <option value="Assigned to">Assigned to</option>
                  <option value="Not Responding">Not Responding</option>
                  <option value="Converted">Converted</option>
                  <option value="Not Intrested">Not Intrested</option>
                </Select>
              </Td>
              <Td textAlign={"center"}>
                <Select
                  w={"40"}
                  size={"sm"}
                  borderRadius={"md"}
                  value={v.assigned}
                  // placeholder="Assigned to ..."
                  onChange={(e) => update_assigned(v.id, e.target.value)}
                >
                  {[<option value={0}>{"Assigned To"}</option>].concat(
                    assignedData.map((v) => (
                      <option value={v.id}>{v.username}</option>
                    ))
                  )}
                </Select>
                {/* <Flex align={"center"} justify={"center"} gap={2}>
                  {v.assigned.length !== 0 && edit_assign_id == v.id ? (
                    <Input
                      placeholder={"Assigned to..."}
                      w={"150px"}
                      disabled={edit_assign_id === v.id ? false : true}
                      value={v.assigned.toString()}
                      onChange={(e) => {
                        setMess_data((pre) =>
                          pre.map((el, i) =>
                            v.id === el.id
                              ? {
                                  ...el,
                                  assigned: e.target.value,
                                }
                              : el
                          )
                        );
                      }}
                    />
                  ) : (
                    v.assigned
                  )}
                  {v.assigned.length == 0 && (
                    <Input
                      placeholder={"Assigned to..."}
                      w={"150px"}
                      disabled={edit_assign_id === v.id ? false : true}
                      value={v.assigned.toString()}
                      onChange={(e) => {
                        setMess_data((pre) =>
                          pre.map((el, i) =>
                            v.id === el.id
                              ? {
                                  ...el,
                                  assigned: e.target.value,
                                }
                              : el
                          )
                        );
                      }}
                    />
                  )}
                  {edit_assign_id == v.id ? (
                    <CustomToolTip
                      button={
                        <GrUpdate
                          size={20}
                          onClick={() => update_assigned(v.id, v.assigned)}
                        />
                      }
                      mess={"Update Assigned to"}
                    />
                  ) : (
                    <CustomToolTip
                      button={
                        <FiEdit3
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            setEdit_assign_id(v.id);
                          }}
                        />
                      }
                      mess={"Edit Assigned to"}
                    />
                  )}
                </Flex> */}
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={2}>
                  <CustomToolTip
                    button={
                      <FiEye
                        size={20}
                        cursor={"pointer"}
                        onClick={() => {
                          setId(v.id);
                          setText(v.comment);
                          onCOpen1();
                        }}
                      />
                    }
                    mess={"View Comment"}
                  />
                  <CustomToolTip
                    button={
                      <FiEdit3
                        size={20}
                        cursor={"pointer"}
                        onClick={() => {
                          setId(v.id);
                          setText(v.comment);
                          onCOpen();
                        }}
                      />
                    }
                    mess={"Edit Comment"}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
          pagination={true}
          rows={rows}
          page={page}
          initialValue={initialValue}
          onChange={(v) => {
            setRows(v.target.value);
            search.length > 2
              ? store
                  .dispatch(
                    getSearchMessages({
                      page: 1,
                      rows: v.target.value,
                      value: value,
                      v: search,
                    })
                  )
                  .then(unwrapResult)
                  .then((v) =>
                    setMess_data(
                      v.message == "No Data available" ? [] : v.message
                    )
                  )
              : store
                  .dispatch(getMessages({ page: 1, rows: v.target.value }))
                  .then(unwrapResult)
                  .then((v) => setMess_data(v.message));
            setInitialValue(1);
            setpage(1);
          }}
          onPrevious={() => {
            initialValue == 1 ||
              setInitialValue(Number(initialValue) - Number(rows));
            page == 1 || setpage(page - 1);
            page === 1 ||
              (search.length > 2
                ? store
                    .dispatch(
                      getSearchMessages({
                        page: page - 1,
                        rows: rows,
                        value: value,
                        v: search,
                      })
                    )
                    .then(unwrapResult)
                    .then((v) =>
                      setMess_data(
                        v.message == "No Data available" ? [] : v.message
                      )
                    )
                : store
                    .dispatch(getMessages({ page: page - 1, rows: rows }))
                    .then(unwrapResult)
                    .then((v) => setMess_data(v.message)));
          }}
          onNext={() => {
            rows * page >= total_message ||
              setInitialValue(Number(initialValue) + Number(rows));
            rows * page >= total_message || setpage(page + 1);
            rows * page >= total_message ||
              (search.length > 2
                ? store
                    .dispatch(
                      getSearchMessages({
                        page: page + 1,
                        rows: rows,
                        value: value,
                        v: search,
                      })
                    )
                    .then(unwrapResult)
                    .then((v) =>
                      setMess_data(
                        v.message == "No Data available" ? [] : v.message
                      )
                    )
                : store
                    .dispatch(getMessages({ page: page + 1, rows: rows }))
                    .then(unwrapResult)
                    .then((v) => setMess_data(v.message)));
          }}
          total_value={total_message}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Message Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading fontSize={["md", "lg", "xl"]}>{data.name}</Heading>
            <Text fontSize={["sm", "md", "lg"]} my={2}>
              {data.message}
            </Text>
            <Text fontSize={["xs", "sm", "md"]} textAlign={"end"}>
              {data.datetime}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      <CustomPopup
        isOpen={isCOpen1}
        onClose={onCClose1}
        title={"Comment"}
        single_button
        mess={
          <Stack>
            {text.length == 0 ? (
              <Text>No Comment, Please add comment</Text>
            ) : (
              <Text>{text}</Text>
            )}
          </Stack>
        }
      />
      <CustomPopup
        isOpen={isCOpen}
        onClose={onCClose}
        title={"Comment"}
        b_name={"Update"}
        onClick={update}
        b_color={"#FFBF00"}
        mess={
          <Stack>
            {text.length == 0 && <Text>No Comment, Please add comment</Text>}
            <CustomInput
              area1
              onlyone
              value1={text}
              onChange1={(e) => setText(e.target.value)}
              placeholder1={"Enter Comment..."}
            />
          </Stack>
        }
      />
    </Container>
  );
};

export default Messages;
