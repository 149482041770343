import {
  Card,
  Center,
  Container,
  Flex,
  FormLabel,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import HeadingText from "../../components/HeadingText";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

import {
  addUpdateClaim,
  add_background_image,
  alerts_api,
  video_image,
} from "../../utils/apis";
import { BiArrowBack } from "react-icons/bi";
import { ID, isValidEmail } from "../../utils/utils";
import store from "../../redux/store";
import { claim_action } from "../../redux/slice/claimSlice";
import { getUsers } from "../../redux/slice/usersSlice";
import { useSelector } from "react-redux";

const AddAlerts = () => {
  const [isLarger] = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Obj, setObj] = useState({});
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);

  const { state } = useLocation();
  const { users_list, total_users_list } = useSelector((state) => state.users);

  const list = [];
  for (let i = 0; i < total_users_list.length; i++) {
    const element = {
      label: total_users_list[i].Name,
      value: total_users_list[i].id,
      // +
      // "," +
      // total_users_list[i].Email +
      // "," +
      // total_users_list[i].mobile,
    };
    list.push(element);
  }

  const all_user = [{ label: "All Users", value: "all" }];
  const all_user_list = all_user.concat(list);

  const validation = () => {
    if (!Obj?.alert || !Obj?.users || Object.keys(Obj?.users).length == 0) {
      setObj({
        ...Obj,
        alertval: !Obj?.alert ? true : false,
        alertmess: !Obj?.alert ? "alert message is required" : "",
        usersval:
          !Obj?.users || Object.keys(Obj?.users).length == 0 ? true : false,
        usersmess:
          !Obj?.users || Object.keys(Obj?.users).length == 0
            ? "User is required"
            : "",
      });
      return;
    } else {
      add_alerts();
    }
  };

  const add_alerts = () => {
    const body = new FormData();
    body.append("action", "sendAlert");
    body.append("message", Obj.alert);
    Obj.users?.forEach((v) => body.append("cus_id[]", v.value));

    alerts_api(body, setLoading, state).then((v) => v.success && navigate(-1));
  };

  useEffect(() => {
    store.dispatch(getUsers({ id: user.userid, rows: 10, page: 1 }));
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #f00",
    }),
  };
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <HeadingText title={"Add Alert"} />
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Card bg={"#fff"} mt={2} p={5}>
        <Flex gap={3} flexDirection={isLarger ? "row" : "column"}>
          <CustomInput
            label1={"Alert Message"}
            placeholder1={"Enter Alert Message Here"}
            value1={Obj.alert}
            area1={true}
            onChange1={(e) =>
              setObj({
                ...Obj,
                alert: e.target.value,
                alertval: false,
                alertmess: "",
              })
            }
            onlyone
            errorBorder1={Obj.alertval}
            error1={Obj.alertmess}
          />
          <Flex flexDir={"column"} flex={1} mt={2}>
            <FormLabel marginBottom={2} marginLeft={2}>
              Select Users*
            </FormLabel>
            <Select
              title={" Select Users*"}
              placeholder={"Search and Select Users"}
              options={
                Obj?.users?.find((v) => v.value == "all")
                  ? all_user
                  : all_user_list
              }
              isMulti={true}
              value={Obj.users}
              isSearchable
              // filterOption={customFilter}
              onChange={(v) => {
                setObj({ ...Obj, users: v, usersmess: "", usersval: false });
              }}
              styles={Obj.usersval && customStyles}
            />
            {
              <Text fontSize={"sm"} color={"#f00"}>
                {Obj.usersmess}
              </Text>
            }
          </Flex>
        </Flex>
        <Center mt={3}>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => validation()}
            // onClick={() => add_alerts()}
          />
        </Center>
      </Card>
    </Container>
  );
};

export default AddAlerts;
