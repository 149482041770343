import React, { useEffect, useState } from "react";
import {
  Center,
  Container,
  Flex,
  FormLabel,
  Heading,
  Image,
  Link,
  RadioGroup,
  Spacer,
  Spinner,
  Stack,
  Switch,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import store from "../../redux/store";
import {
  delete_background_img,
  get_background_img,
} from "../../redux/slice/backgroundSlice";
import { Radio } from "antd";
import CustomPopup from "../../components/CustomPopup";
import ImageModal from "../../components/ImageModal";
import { add_background_image } from "../../utils/apis";

const BackgroundImg = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { background_list, delete_loading, background_loading } = useSelector(
    (state) => state.background
  );
  const [id, setId] = useState("");
  const [url, setUrl] = useState("");
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();

  useEffect(() => {
    store.dispatch(get_background_img("web"));
  }, []);

  const onDelete = async () => {
    await store.dispatch(delete_background_img({ id: id }));
    store.dispatch(get_background_img("web"));
  };

  const onUpdate = async (id) => {
    const body = new FormData();
    body.append("action", "update_status");
    body.append("id", id);
    body.append("type", "web");
    add_background_image(body, setLoading, "web");
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      {background_loading && background_list.length === 0 ? (
        <Loader />
      ) : background_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          headData={["S.No.", "Title", "Image", "Display", "Actions"]}
          body={background_list.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + 1}</Td>
              <Td textAlign={"center"} cursor={"pointer"}>
                {v?.Title}
              </Td>
              <Td textAlign={"center"}>
                <Center>
                  <Image
                    onClick={() => {
                      onViewOpen();
                      setUrl(v?.Img_url);
                    }}
                    src={v?.Img_url}
                    w={"16"}
                    alt={v?.title || "?"}
                    objectFit={"contain"}
                  />
                </Center>
              </Td>
              <Td textAlign={"center"} cursor={"pointer"}>
                <Switch
                  size="lg"
                  isChecked={v?.Status == "1"}
                  onChange={() => {
                    if (v?.Status != "1") {
                      onUpdate(v?.ID);
                    }
                  }}
                  id="isRequired"
                />
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  {/* <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={() => navigate("edit-claim", { state: v })}
                  /> */}
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      onOpen();
                      setId(v?.ID);
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}

      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? you want to delete Background Images"}
        onClick={() => {
          onDelete();
          onClose();
        }}
      />
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
    </Container>
  );
};

export default BackgroundImg;
