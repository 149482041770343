import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
import store from "../store";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const getUsers = createAsyncThunk("users", async (data) => {
  const body = new FormData();
  body.append("action", "list");
  body.append("userid", data.id);
  body.append("page", data.page);
  body.append("limit", data.rows);

  const response = await fetch(base_url + "api/profile.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  store.dispatch(getAll(res.totalcount));
  return res;
});

export const getSearchUsers = createAsyncThunk("searchusers", async (data) => {
  const body = new FormData();
  body.append("action", "filter");
  body.append("userid", user.userid);
  body.append("page", data.page);
  body.append("limit", data.rows);
  body.append(
    "filter",
    data.value == "All" ? data.value : data.value.toLowerCase()
  );
  body.append("filterVal", data.v);
  const response = await fetch(base_url + "api/profile.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});
export const getAll = createAsyncThunk("all", async (limit) => {
  const body = new FormData();
  body.append("action", "list");
  body.append("userid", user.userid);
  body.append("page", 1);
  body.append("limit", limit);

  const response = await fetch(base_url + "api/profile.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();

  return res;
});

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users_list: [],
    u_loading: false,
    t_loading: false,
    total_users: 0,
    total_users_list: [],
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, action) => {
      state.u_loading = true;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.u_loading = false;
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      const data = action.payload;
      state.u_loading = false;

      if (data.success) {
        state.users_list = data.message;
        state.total_users = data.totalcount;
      } else {
        state.total_users = 0;
        state.users_list = [];
      }
    });

    builder.addCase(getSearchUsers.pending, (state, action) => {
      state.u_loading = true;
    });
    builder.addCase(getSearchUsers.rejected, (state, action) => {
      state.u_loading = false;
    });

    builder.addCase(getSearchUsers.fulfilled, (state, action) => {
      const data = action.payload;
      state.u_loading = false;

      if (data.success) {
        state.users_list = data.message;
        state.total_users = data.totalcount;
      } else {
        state.total_users = 0;
        state.users_list = [];
      }
    });
    builder.addCase(getAll.pending, (state, action) => {
      state.t_loading = true;
    });
    builder.addCase(getAll.rejected, (state, action) => {
      state.t_loading = false;
    });

    builder.addCase(getAll.fulfilled, (state, action) => {
      const data = action.payload;
      state.t_loading = false;
      if (data.success) {
        state.total_users_list = data.message;
      } else {
        state.total_users = 0;
        state.total_users_list = [];
      }
    });
  },
});

export default usersSlice.reducer;
