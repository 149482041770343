import { Flex, Text } from "@chakra-ui/react";
import React from "react";

const TitleValue = ({ title, value }) => {
  return (
    <Flex gap={2} flex={1} mt={1}>
      <Text fontSize={16} fontWeight={"normal"} p={0} m={0}>
        {title}
      </Text>
      :
      <Text fontSize={16} fontWeight={"semibold"} m={0}>
        {value}
      </Text>
    </Flex>
  );
};

export default TitleValue;
