import { Card, Container, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";

const UserChart = ({ data }) => {
  const { total_users_list } = useSelector((state) => state.users);

  return (
    <>
      <Heading fontSize={"xl"} mt={4}>
        Registered Users
      </Heading>
      <Flex flexDirection={["column", "row"]} gap={3} mt={2}>
        <Container maxW={"full"} h={"sm"} p={0}>
          <Card h={"sm"} w={"full"} p={3}>
            <Text fontSize={"md"} mb={2}>
              Total Users:{total_users_list?.length}
            </Text>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#007BC9" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Container>
        <Container maxW={"full"} h={"sm"} p={0}>
          <Card h={"sm"} p={2} m={0}>
            <Text fontSize={"md"} mb={2}>
              Total Users:{total_users_list?.length}
            </Text>
            <ResponsiveContainer
              width="100%"
              height="100%"
              // maxHeight={"100%"}
              // minWidth={"100%"}
            >
              <LineChart
                data={data}
                margin={
                  {
                    // top: 5,
                    // right: 30,
                    // left: 20,
                    // bottom: 5,
                  }
                }
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#007BC9"
                  activeDot={{ r: 5 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Container>
      </Flex>
    </>
  );
};

export default UserChart;
