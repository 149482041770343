import {
  Card,
  Center,
  Container,
  Flex,
  FormLabel,
  Heading,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { addUserDocument } from "../../utils/apis";
import { toast } from "react-toastify";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { ID } from "../../utils/utils";
import moment from "moment";

const options = [
  { value: "Aadhar", label: "Aadhar" },
  { value: "PAN", label: "PAN" },
  { value: "RC", label: "RC" },
  { value: "Driving license", label: "Driving License" },
  { value: "Passport", label: "Passport" },
  { value: "Pollution Certificate", label: "Pollution Certificate" },
  { value: "Others", label: "Others" },
];

const AddDocument = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [d_data, setD_data] = useState({});


  const add_document = () => {
    if (!d_data.name || !d_data.img) {
      toast.info("Please fill all fields");
      return;
    }
    if (
      !(selectedOption.label == "Aadhar" || selectedOption.label == "PAN") &&
      !d_data.e_date
    ) {
      toast.info("Please fill all fields");
      return;
    }

    const body = new FormData();
    body.append("action", "create");
    body.append("userid", location.state);
    body.append("addedby", ID);
    body.append("doctype", selectedOption.value);
    body.append("docname", d_data.name);
    body.append("docfile", d_data.img);
    selectedOption.value == "Aadhar" ||
      selectedOption.value == "PAN" ||
      body.append("expiry", d_data.e_date);
    addUserDocument(body, setLoading).then((v) => v.success && navigate(-1));
  };
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        add_document();
      }
    },
    [d_data, selectedOption]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Add Document</Heading>
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Spacer h={3} />
      <Flex maxW={"xl"}>
        <div style={{ flex: 1 }}>
          <FormLabel marginBottom={2}>Select document type*</FormLabel>
          <Select
            value={selectedOption}
            onChange={(v) => {
              setSelectedOption(v);
              setD_data({});
            }}
            options={options}
            placeholder={"Select document..."}
          />
        </div>
      </Flex>
      {selectedOption == null || (
        <Card p={5} borderRadius={10} shadow={"base"} mt={3}>
          <Heading fontSize={"lg"}>{selectedOption?.label}</Heading>
          <Flex maxW={"xl"} gap={2}>
            <SelectImage
              onChange={(e) => setD_data({ ...d_data, img: e.target.files[0] })}
              filename={d_data?.img?.name}
              url={d_data?.img}
              mt={2}
              label={"Upload image*"}
            />
            <Stack />
          </Flex>
          <CustomInput
            label1={selectedOption?.label}
            placeholder1={`Enter ${selectedOption?.label}`}
            value1={Object.keys(d_data).length == 0 ? "" : d_data.name}
            onChange1={(e) => setD_data({ ...d_data, name: e.target.value })}
            single
          />

          {selectedOption.value == "Aadhar" ||
            selectedOption.value == "PAN" || (
              <CustomInput
                label1={"Expiry Date*"}
                placeholder1={"Enter Expiry Date"}
                min1={moment(new Date()).format("YYYY-MM-DD")}
                value1={d_data.e_date}
                onChange1={(e) => {
                  setD_data({ ...d_data, e_date: e.target.value });
                }}
                type1={"date"}
                single
              />
            )}
          <Center mt={5}>
            <CustomButton
              title={"Submit"}
              onClick={() => add_document()}
              loading={loading}
            />
          </Center>
        </Card>
      )}
    </Container>
  );
};

export default AddDocument;
