import { Card, Container, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { useSelector } from "react-redux";

const PolicyChart = ({ data1, data }) => {
  const { dashboard_data, dashboard_list } = useSelector(
    (state) => state.dashboard
  );

  const series = [
    {
      name: "Policy",
      data: dashboard_list?.policies?.map((v) => ({
        category: v.date,
        value: v.count,
      })),
    },
    {
      name: "Policy expire",
      data: dashboard_list?.policiesexpire?.map((v) => ({
        category: v.date,
        value: v.expcount,
      })),
    },
  ];

  return (
    <>
      <Heading fontSize={"xl"} mt={4}>
        Policy Status
      </Heading>
      <Flex flexDirection={["column", "row"]} gap={3} mt={2}>
        <Container maxW={"full"} h={"sm"} p={0}>
          <Card h={"sm"} p={2}>
            <Text fontSize={"md"} mb={2}>
              Total Policies:{dashboard_data?.policies?.totalpolicies}
            </Text>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#007BC9" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Container>
        <Container maxW={"full"} h={"sm"} p={0}>
          <Card h={"sm"} p={2}>
            <Text fontSize={"md"} mb={2}>
              Total Policies:{dashboard_data?.policies?.totalpolicies}
            </Text>
            {/* <ResponsiveContainer width="100%" height="100%">
              <LineChart width={480} height={300}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="category"
                  type="category"
                  fontSize={12}
                  allowDuplicatedCategory={false}
                />
                <YAxis dataKey="value" />
                <Tooltip />
                <Legend />
                {series.map((s, i) => (
                  <Line
                    dataKey="value"
                    data={s.data}
                    name={s.name}
                    key={s.name}
                    stroke={i / 2 == 0 ? "blue" : "#f00"}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer> */}
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={dashboard_list.combinedpolicies}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="blue"
                  // activeDot={{ r: 8 }}
                  name="Policy"
                />
                <Line
                  type="monotone"
                  dataKey="expcount"
                  name="Policy Expire"
                  stroke="#f00"
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Container>
      </Flex>
    </>
  );
};

export default PolicyChart;
