import {
  Box,
  Card,
  Center,
  Checkbox,
  Container,
  Flex,
  FormLabel,
  Heading,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import CustomInput from "../../components/CustomInput";
import Select from "react-select";
import { employee_action } from "../../utils/apis";
import { toast } from "react-toastify";
import store from "../../redux/store";
import { getEmployee_list } from "../../redux/slice/employeeSlice";
import { useSelector } from "react-redux";

const list = [
  { label: "Manager", value: "Manager" },
  { label: "Team lead", value: "Team lead" },
  { label: "Team member", value: "Team member" },
];
const list1 = [{ label: "Team member", value: "Team member" }];
const AddEmployee = () => {
  const { employee_list, e_loading } = useSelector((state) => state.employee);
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const navigate = useNavigate();
  const [e_data, setE_data] = useState({});
  const [loading, setLoading] = useState(false);
  const [per, setPer] = useState([]);

  const validation = () => {
    if (
      !e_data.empid ||
      !e_data.name ||
      !e_data.password ||
      (!e_data.motor && !e_data.health && !e_data.employee)
    ) {
      setE_data({
        ...e_data,
        idval: !e_data.empid ? true : false,
        idmess: !e_data.empid ? "Employee id is required" : "",
        nameval: !e_data.name ? true : false,
        namemess: !e_data.name ? "Name is required" : "",
        passwordval: !e_data.password ? true : false,
        passwordmess: !e_data.password ? "Password is required" : "",
        perval:
          !e_data.motor && !e_data.health && !e_data.employee ? true : false,
        permess:
          !e_data.motor && !e_data.health && !e_data.employee
            ? "Permission is required"
            : "",
      });
      return;
    } else {
      add_employee();
    }
  };

  const add_employee = () => {
    const body = new FormData();
    body.append("action", "create");
    body.append("role", e_data.role.value);
    body.append("empid", e_data.empid);
    body.append("name", e_data.name);
    body.append("password", e_data.password);
    body.append("permission", per.toString());
    e_data?.role?.value == "Team member" &&
      body.append("assignedto", e_data.assign.value);
    employee_action(body, setLoading).then((v) => {
      if (v.success) {
        toast.success(v.message);
        store.dispatch(getEmployee_list());
        navigate(-1);
      } else {
        toast.error(v.message);
      }
    });
  };
  const e_list = [];
  for (let i = 0; i < employee_list.length; i++) {
    const element = {
      value: employee_list[i].id,
      label:
        employee_list[i].username +
        " (" +
        employee_list[i].emp_id +
        ") " +
        employee_list[i].admin_type,
    };
    e_list.push(element);
  }

  return (
    <Container h={"full"} w={"full"} maxW={"container"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Add Employee</Heading>
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Spacer h={3} />
      <Card px={3} pb={3}>
        <Flex
          flex={1}
          gap={3}
          flexDirection={["column", "column", "row"]}
          mt={3}
        >
          <div style={{ flex: 1 }}>
            <FormLabel marginBottom={2}>Role</FormLabel>
            <Select
              value={e_data.role}
              onChange={(v) => {
                setE_data({ ...e_data, role: v, employee: false });
                setPer((pre) => pre.filter((v) => v !== "Employee"));
              }}
              options={user.role === "Team lead" ? list1 : list}
              placeholder={"Select employee role..."}
            />
          </div>

          <div style={{ flex: 1 }}>
            {e_data?.role?.value == "Team member" && (
              <div style={{ flex: 1 }}>
                <FormLabel marginBottom={2}>Assigned to</FormLabel>
                <Select
                  value={e_data.assign}
                  onChange={(v) => {
                    setE_data({ ...e_data, assign: v });
                  }}
                  options={e_list}
                  placeholder={"Select assigned to"}
                />
              </div>
            )}
          </div>
        </Flex>

        {e_data.role && (
          <>
            <CustomInput
              label1={"Employee id"}
              placeholder1={"Enter empid"}
              value1={e_data.empid}
              onChange1={(e) => {
                setE_data({
                  ...e_data,
                  empid: e.target.value.trimStart(),
                  idmess: "",
                  idval: false,
                });
              }}
              error1={e_data.idmess}
              errorBorder1={e_data.idval && "#f00"}
              label2={"Name"}
              placeholder2={"Enter name"}
              value2={e_data.name}
              onChange2={(e) => {
                setE_data({
                  ...e_data,
                  name: e.target.value.trimStart(),
                  namemess: "",
                  nameval: false,
                });
              }}
              error2={e_data.namemess}
              errorBorder2={e_data.nameval && "#f00"}
            />
            <CustomInput
              label1={"Password"}
              placeholder1={"Enter password"}
              value1={e_data.password}
              onChange1={(e) => {
                setE_data({
                  ...e_data,
                  password: e.target.value.trimStart(),
                  passwordmess: "",
                  passwordval: false,
                });
              }}
              error1={e_data.passwordmess}
              errorBorder1={e_data.passwordval && "#f00"}
              single
              item={
                <>
                  <FormLabel marginBottom={0} marginLeft={2}>
                    Permission
                  </FormLabel>
                  <Flex gap={10}>
                    <Checkbox
                      alignSelf={"self-start"}
                      isChecked={e_data.motor}
                      onChange={(e) => {
                        setE_data({
                          ...e_data,
                          permess: "",
                          motor: e.target.checked,
                        });
                        setPer((pre) =>
                          pre.find((v) => v == "Motor")
                            ? pre.filter((v) => v !== "Motor")
                            : [...pre, "Motor"]
                        );
                      }}
                      my={3}
                      fontSize={"xs"}
                    >
                      Motor
                    </Checkbox>
                    <Checkbox
                      alignSelf={"self-start"}
                      isChecked={e_data.health}
                      onChange={(e) => {
                        setE_data({
                          ...e_data,
                          permess: "",
                          health: e.target.checked,
                        });
                        setPer((pre) =>
                          pre.find((v) => v == "Health")
                            ? pre.filter((v) => v !== "Health")
                            : [...pre, "Health"]
                        );
                      }}
                      my={3}
                      fontSize={"xs"}
                    >
                      Health
                    </Checkbox>
                    {e_data.role.value === "Manager" && (
                      <Checkbox
                        alignSelf={"self-start"}
                        isChecked={e_data.employee}
                        onChange={(e) => {
                          setE_data({
                            ...e_data,
                            permess: "",
                            employee: e.target.checked,
                          });
                          setPer((pre) =>
                            pre.find((v) => v == "Employee")
                              ? pre.filter((v) => v !== "Employee")
                              : [...pre, "Employee"]
                          );
                        }}
                        my={3}
                        fontSize={"xs"}
                      >
                        Employee
                      </Checkbox>
                    )}
                  </Flex>
                  <Text color={"#f00"}>{e_data.permess}</Text>
                </>
              }
            />

            <Center mt={3}>
              <CustomButton
                title={"Submit"}
                loading={loading}
                onClick={() => validation()}
                // onClick={() => send_notification()}
              />
            </Center>
          </>
        )}
      </Card>
    </Container>
  );
};

export default AddEmployee;
