import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";
import store from "../store";

export const getMessages = createAsyncThunk("messages", async (data) => {
  const body = new FormData();
  body.append("policy", "list");
  body.append("page", data.page);
  body.append("limit", data.rows);
  const response = await fetch(base_url + "api/contact.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();

  store.dispatch(getTotalMessages(res.totalcount));
  return res;
});
export const getTotalMessages = createAsyncThunk(
  "totalmessages",
  async (data) => {
    const body = new FormData();
    body.append("policy", "list");
    body.append("page", 1);
    body.append("limit", data);
    const response = await fetch(base_url + "api/contact.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);
export const getSearchMessages = createAsyncThunk(
  "searchmessages",
  async (data) => {
    const body = new FormData();
    body.append("policy", "filter");
    body.append("page", data.page);
    body.append("limit", data.rows);
    body.append(
      "filter",
      data.value == "All" ? data.value : data.value.toLowerCase()
    );
    body.append("filterVal", data.v);
    const response = await fetch(base_url + "api/contact.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

const messageSlice = createSlice({
  name: "messages",
  initialState: {
    message_list: [],
    total_message_list: [],
    loading: false,
    total_message: 0,
    Total: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(getMessages.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMessages.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      const data = action.payload;
      state.loading = false;
      state.total_message = data.totalcount;
      state.Total = data.totalcount;
      if (data.success) {
        state.message_list = data.message;
      } else {
        state.message_list = [];
      }
    });

    builder.addCase(getSearchMessages.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSearchMessages.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSearchMessages.fulfilled, (state, action) => {
      const data = action.payload;
      state.loading = false;
      state.total_message = data.totalcount;
      if (data.success) {
        state.message_list =
          data.message == "No Data available" ? [] : data.message;
      } else {
        state.message_list = [];
      }
    });
    builder.addCase(getTotalMessages.fulfilled, (state, action) => {
      const data = action.payload;

      if (data.success) {
        state.total_message_list =
          data.message == "No Data available" ? [] : data.message;
      } else {
        state.total_message_list = [];
      }
    });
  },
});

export default messageSlice.reducer;
