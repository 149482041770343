import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import store from "../../redux/store";
import CustomPopup from "../../components/CustomPopup";
import CustomInput from "../../components/CustomInput";
import TableHeader from "../../components/TableHeader";
import { user_policies_action } from "../../utils/apis";
import { FiEdit3, FiEye } from "react-icons/fi";
import CustomToolTip from "../../components/CustomTooltip";
import { unwrapResult } from "@reduxjs/toolkit";
import { base_url, exportPDF } from "../../utils/utils";
import {
  getAllPolicy,
  getSearchAllPolicy_list,
} from "../../redux/slice/policylistSlice";
import moment from "moment";
import ImageModal from "../../components/ImageModal";
import { DateRange, DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import CustomButton from "../../components/CustomButton";

const AllUserList = () => {
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const navigate = useNavigate();
  const [assignedData, setAssignedData] = useState([]);
  const [initialValue, setInitialValue] = useState(1);
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const { all_users_policy, all_loading, total_policies, total_policies_list } =
    useSelector((state) => state.policies);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mess_data, setMess_data] = useState([]);
  const {
    isOpen: isDateOpen,
    onOpen: onDateOpen,
    onClose: onDateClose,
  } = useDisclosure();
  const {
    isOpen: isCOpen,
    onOpen: onCOpen,
    onClose: onCClose,
  } = useDisclosure();
  const {
    isOpen: isCOpen1,
    onOpen: onCOpen1,
    onClose: onCClose1,
  } = useDisclosure();
  const [data, setData] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [text, setText] = useState("");
  const [id, setId] = useState("");
  const [value, setValue] = useState("All");
  const [search, setSearch] = useState("");
  const [edit_assign_id, setEdit_assign_id] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [url, setUrl] = useState("");

  const getAssigned_data = async () => {
    const body = new FormData();
    body.append("action", "assignedto");
    body.append("userid", user.userid);

    const response = await fetch(base_url + "api/user-policies.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    if (res.success) {
      setAssignedData(res.message);
    } else {
      setAssignedData([]);
    }
  };

  useEffect(() => {
    setMess_data(all_users_policy);
    getAssigned_data();
    store
      .dispatch(getAllPolicy({ rows: rows, page: page }))
      .then(unwrapResult)
      .then((v) =>
        setMess_data(v.message == "No Data available" ? [] : v.message)
      );
  }, []);
  const update = () => {
    onCClose();
    const body = new FormData();
    body.append("action", "comment");
    body.append("id", id);
    body.append("comment", text);
    user_policies_action(body, setLoading1).then((v) => {
      store
        .dispatch(getAllPolicy({ rows: rows, page: page }))
        .then(unwrapResult)
        .then((v) =>
          setMess_data(v.message == "No Data available" ? [] : v.message)
        );
    });
  };
  const update_status = (id, v) => {
    const body = new FormData();
    body.append("action", "status");
    body.append("id", id);
    body.append("status", v.toString());
    user_policies_action(body, setLoading1).then((v) => {
      store
        .dispatch(getAllPolicy({ rows: rows, page: page }))
        .then(unwrapResult)
        .then((v) =>
          setMess_data(v.message == "No Data available" ? [] : v.message)
        );
    });
  };
  const update_assigned = (p_id, e_id) => {
    // if (text.length == 0) {
    //   toast.info("Please enter assigned to");
    //   return;
    // }
    const body = new FormData();
    body.append("action", "assigned");
    body.append("id", p_id);
    body.append("assigned", e_id);
    user_policies_action(body, setLoading1).then((v) => {
      setEdit_assign_id("");
      store
        .dispatch(getAllPolicy({ rows: rows, page: page }))
        .then(unwrapResult)
        .then((v) =>
          setMess_data(v.message == "No Data available" ? [] : v.message)
        );
    });
  };
  const table_data = total_policies_list?.map((elt, i) => [
    i + 1,
    elt.name,
    elt.insurance_type,
    elt.policy_company,
    elt.policy_plan,
    elt.policy_number,
    elt.expiry_date,
    elt.Email,
    elt.mobile,
    elt.status == 0 ? "New" : elt.status,
    elt.assigned,
    elt.comment,
  ]);
  const export_pdf = () => {
    exportPDF({
      table_data: table_data,
      table_headers: [
        "S.No.",
        "Name",
        "Insurance Type",
        "Policy Company",
        "Policy Plan",
        "Policy Number",
        "Expiry Date",
        "Email",
        "mobile",
        "Status",
        "Assigned to",
        "Comment",
      ],

      fileName: "users-policies-list",
      tableName: "Insugo Users Policies List",
    });
  };
  const headers = [
    { label: "S.No.", key: "no" },
    { label: "Name", key: "name" },
    { label: "Insurance Type", key: "type" },
    { label: "Policy Company", key: "Company" },
    { label: "Policy Plan", key: "Plan" },
    { label: "Policy Number", key: "pnumber" },
    { label: "Expiry Date", key: "Date" },
    { label: "Email", key: "Email" },
    { label: "mobile", key: "mobile" },
    { label: "Status", key: "status" },
    { label: "Assigned", key: "assigned" },
    { label: "Comment", key: "comment" },
  ];
  const CSVdata = total_policies_list?.map((elt, i) => ({
    no: i + 1,
    name: elt.name,
    type: elt.insurance_type,
    Company: elt.policy_company,
    Plan: elt.policy_plan,
    pnumber: elt.policy_number,
    Date: elt.expiry_date,
    Email: elt.Email,
    mobile: elt.mobile,
    status: elt.status == 0 ? "New" : elt.status,
    assigned: elt.assigned,
    comment: elt.comment,
  }));
  function daysRemaining(date) {
    var eventdate = moment(date);
    var todaysdate = moment();
    return eventdate.diff(todaysdate, "days");
  }

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Heading fontSize={"xl"}>All Users Policy List</Heading>
      <Spacer h={3} />
      <Flex gap={2} align={"center"} mb={1}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Users Policy:
        </Text>
        <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
          {total_policies}
        </Text>
      </Flex>

      <TableHeader
        CSVdata={CSVdata}
        headers={headers}
        onPdfClick={() => export_pdf()}
        date_range={
          (value == "policyexp" && (
            <Box
              borderWidth={1}
              maxW={"lg"}
              paddingBlock={2}
              paddingInline={5}
              flexDirection={"row"}
              display={"flex"}
              gap={5}
              cursor={"pointer"}
              borderRadius={4}
              onClick={() => onDateOpen()}
              bg={"#fff"}
              w={"80"}
            >
              <Text>
                {!startDate && !endDate
                  ? "Select Date Range"
                  : startDate + " - " + endDate}
              </Text>
            </Box>
          )) ||
          (value == "assigned" && (
            <Select
              w={"64"}
              size={"md"}
              borderRadius={"md"}
              value={search}
              onChange={(v) => {
                setpage(1);
                setRows(10);
                v.target.value == 0
                  ? store
                      .dispatch(
                        getAllPolicy({
                          page: page,
                          rows: rows,
                        })
                      )
                      .then(unwrapResult)
                      .then((v) =>
                        setMess_data(
                          v.message == "No Data available" ? [] : v.message
                        )
                      )
                  : store
                      .dispatch(
                        getSearchAllPolicy_list({
                          page: page,
                          rows: rows,
                          value: value.length == 0 ? "All" : value,
                          v: v.target.value,
                        })
                      )
                      .then(unwrapResult)
                      .then((v) =>
                        setMess_data(
                          v.message == "No Data available" ? [] : v.message
                        )
                      );

                setSearch(v.target.value);
              }}
            >
              {[<option value={0}>{"Assigned To"}</option>].concat(
                assignedData.map((v) => (
                  <option value={v.id}>{v.username}</option>
                ))
              )}
            </Select>
          ))
        }
        options={
          <>
            <option value={"All"}>All</option>
            <option value={"name"}>Name</option>
            <option value={"email"}>Email</option>
            <option value={"mobile"}>Mobile</option>
            <option value={"insurance_type"}>Insurance Type</option>
            <option value={"policyname"}>Policy Name</option>
            <option value={"policynumber"}>Policy Number</option>
            <option value={"policycomp"}>Policy Company</option>
            <option value={"policyexp"}>Policy Expiry</option>
            <option value={"status"}>Status</option>
            {user.role == "Team member" || (
              <option value={"assigned"}>Assigned</option>
            )}
          </>
        }
        onChange={(v) => {
          setSearch("");
          setValue(v.target.value);
          setStartDate("");
          setEndDate("");
          store
            .dispatch(
              getAllPolicy({
                page: page,
                rows: rows,
              })
            )
            .then(unwrapResult)
            .then((v) =>
              setMess_data(v.message == "No Data available" ? [] : v.message)
            );
        }}
        value={search}
        filename={"users-policies-list.csv"}
        onSerchChange={(v) => {
          setpage(1);
          v.target.value.length <= 2 &&
            store
              .dispatch(
                getAllPolicy({
                  page: page,
                  rows: rows,
                })
              )
              .then(unwrapResult)
              .then((v) =>
                setMess_data(v.message == "No Data available" ? [] : v.message)
              );
          v.target.value.length > 2 &&
            store
              .dispatch(
                getSearchAllPolicy_list({
                  page: page,
                  rows: rows,
                  value: value.length == 0 ? "All" : value,
                  v: v.target.value,
                })
              )
              .then(unwrapResult)
              .then((v) =>
                setMess_data(v.message == "No Data available" ? [] : v.message)
              );

          setSearch(v.target.value);
        }}
      />
      {all_loading && all_users_policy.length === 0 ? (
        <Loader />
      ) : all_users_policy.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          headData={
            user.role == "Team member"
              ? [
                  "S.No.",
                  "Name",
                  "Insurance Type",
                  "policy company",
                  "policy plan",
                  "policy number",
                  "Expiry Date",
                  "policy file",
                  "Email",
                  "mobile",
                  "Status",
                  "Comment",
                ]
              : [
                  "S.No.",
                  "Name",
                  "Insurance Type",
                  "policy company",
                  "policy plan",
                  "policy number",
                  "Expiry Date",
                  "policy file",
                  "Email",
                  "mobile",
                  "Status",
                  "Assigned to",
                  "Comment",
                ]
          }
          body={mess_data
            // ?.filter((item) => {
            //   return (
            //     item.name.toLowerCase().includes(search.toLowerCase()) ||
            //     item.insurance_type
            //       .toLowerCase()
            //       .includes(search.toLowerCase()) ||
            //     item.policy_company
            //       .toLowerCase()
            //       .includes(search.toLowerCase()) ||
            //     item.policy_plan.toLowerCase().includes(search.toLowerCase()) ||
            //     item.policy_number.toLowerCase().includes(search.toLowerCase())
            //   );
            // })
            .map((v, i) => (
              <Tr key={i}>
                <Td textAlign={"center"}>{i + initialValue}</Td>
                <Td textAlign={"center"}>{v.name}</Td>
                <Td textAlign={"center"}>{v.insurance_type}</Td>
                <Td textAlign={"center"}>{v.policy_company}</Td>
                <Td textAlign={"center"}>{v.policy_plan}</Td>
                <Td textAlign={"center"}>{v.policy_number}</Td>
                <Td textAlign={"center"}>
                  <Text
                    p={1}
                    fontWeight={"medium"}
                    borderRadius={3}
                    color={
                      daysRemaining(v.expiry_date) < 7
                        ? "#fff"
                        : daysRemaining(v.expiry_date) < 15
                        ? "#fff"
                        : daysRemaining(v.expiry_date) < 30
                        ? "#fff"
                        : "#000"
                    }
                    bg={
                      daysRemaining(v.expiry_date) < 7
                        ? "#f00"
                        : daysRemaining(v.expiry_date) < 15
                        ? "#FFCC00"
                        : daysRemaining(v.expiry_date) < 30
                        ? "#A9A9A9"
                        : "#fff"
                    }
                  >
                    {v.expiry_date}
                  </Text>
                </Td>
                <Td textAlign={"center"}>
                  <Text
                    fontSize={"md"}
                    fontWeight={"semibold"}
                    // color={"#0000ff"}
                    cursor={"pointer"}
                    onClick={() => {
                      setUrl(v.policy_file);
                      onViewOpen();
                    }}
                  >
                    View File
                  </Text>
                </Td>
                <Td textAlign={"center"}>{v.Email}</Td>
                <Td textAlign={"center"}>{v.mobile}</Td>

                <Td textAlign={"center"} w={"36"}>
                  <Select
                    w={"32"}
                    size={"sm"}
                    borderRadius={"md"}
                    value={v.status == 0 ? "New" : v.status}
                    onChange={(e) => update_status(v.id, e.target.value)}
                  >
                    <option value="New">New</option>
                    <option value="Open">Open</option>
                    <option value="Converted">Converted</option>
                    <option value="Not Converted">Not Converted</option>
                  </Select>
                </Td>
                {user.role == "Team member" || (
                  <Td textAlign={"center"}>
                    <Select
                      w={"40"}
                      size={"sm"}
                      borderRadius={"md"}
                      value={v.assigned}
                      // placeholder="Assigned to ..."
                      onChange={(e) => update_assigned(v.id, e.target.value)}
                    >
                      {[<option value={0}>{"Assigned To"}</option>].concat(
                        assignedData.map((v) => (
                          <option value={v.id}>{v.username}</option>
                        ))
                      )}
                    </Select>
                    {/* <Flex align={"center"} justify={"center"} gap={2}>
                    {v.assigned.length !== 0 && edit_assign_id == v.id ? (
                      <Input
                        placeholder={"Assigned to..."}
                        w={"150px"}
                        disabled={edit_assign_id === v.id ? false : true}
                        value={v.assigned.toString()}
                        onChange={(e) => {
                          setMess_data((pre) =>
                            pre.map((el, i) =>
                              v.id === el.id
                                ? {
                                    ...el,
                                    assigned: e.target.value,
                                  }
                                : el
                            )
                          );
                        }}
                      />
                    ) : (
                      v.assigned
                    )}
                    {v.assigned.length == 0 && (
                      <Input
                        placeholder={"Assigned to..."}
                        w={"150px"}
                        disabled={edit_assign_id === v.id ? false : true}
                        value={v.assigned.toString()}
                        onChange={(e) => {
                          setMess_data((pre) =>
                            pre.map((el, i) =>
                              v.id === el.id
                                ? {
                                    ...el,
                                    assigned: e.target.value,
                                  }
                                : el
                            )
                          );
                        }}
                      />
                    )}
                    {edit_assign_id == v.id ? (
                      <CustomToolTip
                        button={
                          <GrUpdate
                            size={20}
                            onClick={() => update_assigned(v.id, v.assigned)}
                          />
                        }
                        mess={"Update Assigned to"}
                      />
                    ) : (
                      <CustomToolTip
                        button={
                          <FiEdit3
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              setEdit_assign_id(v.id);
                            }}
                          />
                        }
                        mess={"Edit Assigned to"}
                      />
                    )}
                  </Flex> */}
                  </Td>
                )}
                <Td textAlign={"center"}>
                  <Flex gap={2}>
                    <CustomToolTip
                      button={
                        <FiEye
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            setId(v.id);
                            setText(v.comment);
                            onCOpen1();
                          }}
                        />
                      }
                      mess={"View Comment"}
                    />
                    <CustomToolTip
                      button={
                        <FiEdit3
                          size={20}
                          cursor={"pointer"}
                          onClick={() => {
                            setId(v.id);
                            setText(v.comment);
                            onCOpen();
                          }}
                        />
                      }
                      mess={"Edit Comment"}
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          pagination={true}
          rows={rows}
          page={page}
          initialValue={initialValue}
          onChange={(v) => {
            setRows(v.target.value);
            search.length > 2 || (startDate.length != 0 && endDate.length != 0)
              ? store
                  .dispatch(
                    getSearchAllPolicy_list({
                      page: page,
                      rows: v.target.value,
                      value: value,
                      v:
                        value == "policyexp"
                          ? startDate + "," + endDate
                          : search,
                    })
                  )
                  .then(unwrapResult)
                  .then((v) =>
                    setMess_data(
                      v.message == "No Data available" ? [] : v.message
                    )
                  )
              : store
                  .dispatch(getAllPolicy({ page: 1, rows: v.target.value }))
                  .then(unwrapResult)
                  .then((v) =>
                    setMess_data(
                      v.message == "No Data available" ? [] : v.message
                    )
                  );
            setInitialValue(1);
            setpage(1);
          }}
          onPrevious={() => {
            initialValue == 1 ||
              setInitialValue(Number(initialValue) - Number(rows));
            page == 1 || setpage(page - 1);
            page === 1 ||
              (search.length > 2 ||
              (startDate.length != 0 && endDate.length != 0)
                ? store
                    .dispatch(
                      getSearchAllPolicy_list({
                        page: page - 1,
                        rows: rows,
                        value: value,
                        v:
                          value == "policyexp"
                            ? startDate + "," + endDate
                            : search,
                      })
                    )
                    .then(unwrapResult)
                    .then((v) =>
                      setMess_data(
                        v.message == "No Data available" ? [] : v.message
                      )
                    )
                : store
                    .dispatch(getAllPolicy({ page: page - 1, rows: rows }))
                    .then(unwrapResult)
                    .then((v) => setMess_data(v.message)));
          }}
          onNext={() => {
            rows * page >= total_policies ||
              setInitialValue(Number(initialValue) + Number(rows));
            rows * page >= total_policies || setpage(page + 1);
            rows * page >= total_policies ||
              (search.length > 2 ||
              (startDate.length != 0 && endDate.length != 0)
                ? store
                    .dispatch(
                      getSearchAllPolicy_list({
                        page: page + 1,
                        rows: rows,
                        value: value,
                        v:
                          value == "policyexp"
                            ? startDate + "," + endDate
                            : search,
                      })
                    )
                    .then(unwrapResult)
                    .then((v) =>
                      setMess_data(
                        v.message == "No Data available" ? [] : v.message
                      )
                    )
                : store
                    .dispatch(getAllPolicy({ page: page + 1, rows: rows }))
                    .then(unwrapResult)
                    .then((v) => setMess_data(v.message)));
          }}
          total_value={total_policies}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Message Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading fontSize={["md", "lg", "xl"]}>{data.name}</Heading>
            <Text fontSize={["sm", "md", "lg"]} my={2}>
              {data.message}
            </Text>
            <Text fontSize={["xs", "sm", "md"]} textAlign={"end"}>
              {data.datetime}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      <CustomPopup
        isOpen={isCOpen1}
        onClose={onCClose1}
        title={"Comment"}
        single_button
        mess={
          <Stack>
            {text.length == 0 ? (
              <Text>No Comment, Please add comment</Text>
            ) : (
              <Text>{text}</Text>
            )}
          </Stack>
        }
      />
      <Modal isOpen={isDateOpen}>
        <ModalOverlay />
        <ModalContent alignItems={"center"}>
          <ModalBody>
            <DateRange
              editableDateInputs={false}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </ModalBody>
          <ModalFooter gap={5}>
            <CustomButton
              title={"Cancel"}
              bg={"#A9A9A9"}
              onClick={() => onDateClose()}
            />
            <CustomButton
              title={"Apply"}
              onClick={() => {
                setStartDate(moment(state[0].startDate).format("YYYY-MM-DD"));
                setEndDate(moment(state[0].endDate).format("YYYY-MM-DD"));
                store
                  .dispatch(
                    getSearchAllPolicy_list({
                      page: page,
                      rows: rows,
                      value: value,
                      v:
                        moment(state[0].startDate).format("YYYY-MM-DD") +
                        "," +
                        moment(state[0].endDate).format("YYYY-MM-DD"),
                    })
                  )
                  .then(unwrapResult)
                  .then((v) =>
                    setMess_data(
                      v.message == "No Data available" ? [] : v.message
                    )
                  );
                onDateClose();
              }}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <CustomPopup
        isOpen={isCOpen}
        onClose={onCClose}
        title={"Comment"}
        b_name={"Update"}
        onClick={update}
        b_color={"#FFBF00"}
        mess={
          <Stack>
            {text.length == 0 && <Text>No Comment, Please add comment</Text>}
            <CustomInput
              area1
              onlyone
              value1={text}
              onChange1={(e) => setText(e.target.value)}
              placeholder1={"Enter Comment..."}
            />
          </Stack>
        }
      />
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
    </Container>
  );
};

export default AllUserList;
