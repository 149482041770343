import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
// toast.configure({
//   position: toast.POSITION.BOTTOM_RIGHT,
//   autoClose: 3000,
//   transition: Slide,
//   pauseOnFocusLoss: false,
//   className: css({
//     backgroundColor: "red",
//   }),
//   bodyClassName: css({
//     backgroundColor: "blue",
//     height: "100%",
//     width: "100%",
//   }),
// });
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ToastContainer position="top-center" autoClose={1500} />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);
