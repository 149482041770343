import {
  Box,
  Card,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { getDash_data, getDash_list } from "../../redux/slice/dashboardSlice";
import UserChart from "./UserChart";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import CustomButton from "../../components/CustomButton";
import PolicyChart from "./PolicyChart";
import DocumentChart from "./DocumentChart";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { getClaims } from "../../redux/slice/claimSlice";
import { getUsers } from "../../redux/slice/usersSlice";
import { getMessages } from "../../redux/slice/messageSlice";
import { getNotificationlist } from "../../redux/slice/notificationSlice";
import { getProviders } from "../../redux/slice/providerSlice";
import { getPolicylist } from "../../redux/slice/policylistSlice";

const Dashboard = () => {
  const { dashboard_data, dashboard_list } = useSelector(
    (state) => state.dashboard
  );
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const date = new Date();
  date.setDate(date.getDate() - 60);
  const date1 = new Date();
  date1.setDate(date1.getDate() + 10);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    store.dispatch(getDash_data());
    store.dispatch(getProviders());
    store.dispatch(getPolicylist());
  }, []);
  useEffect(() => {
    store.dispatch(
      getDash_list({
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      })
    );
  }, []);
  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  useEffect(() => {
    store.dispatch(getClaims(user.userid));
    store.dispatch(getUsers({ id: user.userid, page: 1, rows: 5 }));
    store.dispatch(getMessages({ rows: 5, page: 1 }));
    store.dispatch(getNotificationlist({ id: user.userid, rows: 5, page: 1 }));
    store.dispatch(getProviders());
    store.dispatch(getPolicylist());
  }, []);
  return (
    <Container maxH={"full"} maxW={"container"}>
      <SimpleGrid minChildWidth="xs" spacing="10px">
        <Card bg="#fff" p={5} borderRadius={5}>
          <Heading fontSize={"xl"}>Claims</Heading>
          <Text>Total Insurance Company : {dashboard_data?.data?.claims}</Text>
        </Card>
        <Card bg="#fff" p={5} borderRadius={5}>
          <Heading fontSize={"xl"}>Users</Heading>
          <Text>Total Users: {dashboard_data?.data?.users?.total}</Text>
          <Text>active Users: {dashboard_data?.data?.users?.active}</Text>
          <Text>delete Users: {dashboard_data?.data?.users?.delete}</Text>
          <Text>blocked Users: {dashboard_data?.data?.users?.blocked}</Text>
        </Card>
        <Card bg="#fff" p={5} borderRadius={5}>
          <Heading fontSize={"xl"}>Policies</Heading>
          <Text>Total Policies: {dashboard_data?.policies?.totalpolicies}</Text>
          <Text>
            Expires in 30 days Policies:{" "}
            {dashboard_data?.policies?.Expiresin30days}
          </Text>
          <Text>
            Expired in 30 days Policies:{" "}
            {dashboard_data?.policies?.Expiredin30days}
          </Text>
        </Card>
        <Card bg="#fff" p={5} borderRadius={5}>
          <Heading fontSize={"xl"}>Documents</Heading>
          <Text>Total Documents: {dashboard_data?.documents?.totaldocs}</Text>

          <Text>
            Expires in 30 days Policies:{" "}
            {dashboard_data?.documents?.Expiresin30days}
          </Text>
          <Text>
            Expired in 30 days Policies:{" "}
            {dashboard_data?.documents?.Expiredin30days}
          </Text>
        </Card>
      </SimpleGrid>
      <Flex
        flexDirection={["column", "row"]}
        alignItems={["start", "center"]}
        justify={"end"}
        mt={2}
      >
        <Text>Select Date</Text>
        <Box
          borderWidth={1}
          maxW={"lg"}
          paddingBlock={2}
          paddingInline={5}
          flexDirection={"row"}
          display={"flex"}
          gap={5}
          cursor={"pointer"}
          borderRadius={4}
          onClick={() => onOpen()}
          bg={"#fff"}
        >
          <Text>
            {startDate ? moment(startDate).format("YYYY-MM-DD") : "Start date"}
          </Text>
          :
          <Text>
            {endDate ? moment(endDate).format("YYYY-MM-DD") : "End date"}
          </Text>
        </Box>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClick={() => {
          store.dispatch(
            getDash_list({
              startDate: moment(startDate).format("YYYY-MM-DD"),
              endDate: moment(endDate).format("YYYY-MM-DD"),
            })
          );
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"2xl"}>
          <ModalBody>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={(v) => {
                setStartDate(v.selection.startDate);
                setEndDate(v.selection.endDate);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <CustomButton
              title={"Ok"}
              onClick={() => {
                store.dispatch(
                  getDash_list({
                    startDate: moment(startDate).format("YYYY-MM-DD"),
                    endDate: moment(endDate).format("YYYY-MM-DD"),
                  })
                );
                onClose();
              }}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <UserChart data={dashboard_list.users} />
      <PolicyChart
        data={dashboard_list.policies}
        data1={[
          { name: "Policy", data: dashboard_list.policies },
          { name: "Policy expire", data: dashboard_list.policiesexpire },
        ]}
      />
      <DocumentChart
        data={dashboard_list?.documents}
        data1={[
          { name: "Document", data: dashboard_list?.documents },
          { name: "Document expire", data: dashboard_list?.docexpire },
        ]}
      />
    </Container>
  );
};

export default Dashboard;
