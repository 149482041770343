import {
    Box,
    Center,
    Container,
    Flex,
    Heading,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Spacer,
    Td,
    Text,
    Tr,
    useDisclosure,
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import {
    get_all_users_documents_list,
    get_search_users_documents_list,
  } from "../../redux/slice/documentSlice";
  import { useSelector } from "react-redux";
  import store from "../../redux/store";
  import { useNavigate } from "react-router-dom";
  import { unwrapResult } from "@reduxjs/toolkit";
  import TableHeader from "../../components/TableHeader";
  import TableView from "../../components/TableView";
  import Loader from "../../components/Loader";
  import ImageModal from "../../components/ImageModal";
  import { DateRange } from "react-date-range";
  import CustomButton from "../../components/CustomButton";
  import moment from "moment";
  import { exportPDF } from "../../utils/utils";
  
  const AllUsersDocuments = () => {
    const navigate = useNavigate();
    const [initialValue, setInitialValue] = useState(1);
    const [rows, setRows] = useState(10);
    const [page, setpage] = useState(1);
    const [value, setValue] = useState("All");
    const [search, setSearch] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [state, setState] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    const {
      all_users_documents,
      all_doc_loading,
      all_doc_count,
      all_users_documents_list,
    } = useSelector((state) => state.documents);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: isDateOpen,
      onOpen: onDateOpen,
      onClose: onDateClose,
    } = useDisclosure();
    const [mess_data, setMess_data] = useState([]);
    const {
      isOpen: isViewOpen,
      onClose: onViewClose,
      onOpen: onViewOpen,
    } = useDisclosure();
    const [url, setUrl] = useState("");
  
    useEffect(() => {
      setMess_data(all_users_documents);
      store
        .dispatch(get_all_users_documents_list({ rows: rows, page: page }))
        .then(unwrapResult)
        .then((v) =>
          setMess_data(v.message == "No Data available" ? [] : v.message)
        );
    }, []);
    const table_data = all_users_documents_list?.map((elt, i) => [
      i + 1,
      elt.Name,
      elt.doc_type,
      elt.expiry_date,
      elt.cname,
      elt.Email,
      elt.mobile,
    ]);
    const export_pdf = () => {
      exportPDF({
        table_data: table_data,
        table_headers: [
          "S.No.",
          "Document Name",
          "Document Type",
          "Expiry Date",
          "Name",
          "Email",
          "mobile",
        ],
        fileName: "users-documents-list",
        tableName: "Insugo Users documents List",
      });
    };
    const headers = [
      { label: "S.No.", key: "no" },
      { label: "Document Name", key: "docname" },
      { label: "Document Type", key: "type" },
      { label: "Expiry Date", key: "Date" },
      { label: "Name", key: "name" },
      { label: "Email", key: "Email" },
      { label: "mobile", key: "mobile" },
    ];
    const CSVdata = all_users_documents_list?.map((elt, i) => ({
      no: i + 1,
      docname: elt.Name,
      type: elt.doc_type,
      Date: elt.expiry_date,
      name: elt.cname,
      Email: elt.Email,
      mobile: elt.mobile,
    }));
    return (
      <Container h={"full"} w={"full"} maxW={"full"}>
        <Heading fontSize={"xl"}>All Users Documents List</Heading>
        <Spacer h={3} />
        <Flex gap={2} align={"center"} mb={1}>
          <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
            Total Users Documents:
          </Text>
  
          <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
            {all_doc_count}
          </Text>
        </Flex>
        <TableHeader
          CSVdata={CSVdata}
          headers={headers}
          onPdfClick={() => export_pdf()}
          date_range={
            value == "docexp" && (
              <Box
                borderWidth={1}
                maxW={"lg"}
                paddingBlock={2}
                paddingInline={5}
                flexDirection={"row"}
                display={"flex"}
                gap={5}
                cursor={"pointer"}
                borderRadius={4}
                onClick={() => onDateOpen()}
                bg={"#fff"}
                w={"80"}
              >
                <Text>
                  {!startDate && !endDate
                    ? "Select Date Range"
                    : startDate + " - " + endDate}
                </Text>
              </Box>
            )
          }
          options={
            <>
              <option value={"All"}>All</option>
              <option value={"name"}>Name</option>
              <option value={"email"}>Email</option>
              <option value={"mobile"}>Mobile</option>
              <option value={"doc_type"}>Document Type</option>
              <option value={"docname"}>Document Name</option>
              <option value={"docexp"}>Document Expiry</option>
            </>
          }
          onChange={(v) => {
            setValue(v.target.value);
            setStartDate("");
            setEndDate("");
            store
              .dispatch(
                get_all_users_documents_list({
                  page: page,
                  rows: rows,
                })
              )
              .then(unwrapResult)
              .then((v) =>
                setMess_data(v.message == "No Data available" ? [] : v.message)
              );
          }}
          value={search}
          filename={"users-documents-list.csv"}
          onSerchChange={(v) => {
            setpage(1);
            v.target.value.length <= 2 &&
              store
                .dispatch(
                  get_all_users_documents_list({
                    page: page,
                    rows: rows,
                  })
                )
                .then(unwrapResult)
                .then((v) =>
                  setMess_data(v.message == "No Data available" ? [] : v.message)
                );
            v.target.value.length > 2 &&
              store
                .dispatch(
                  get_search_users_documents_list({
                    page: page,
                    rows: rows,
                    value: value.length == 0 ? "All" : value,
                    v: v.target.value,
                  })
                )
                .then(unwrapResult)
                .then((v) =>
                  setMess_data(v.message == "No Data available" ? [] : v.message)
                );
  
            setSearch(v.target.value);
          }}
        />
  
        {all_doc_loading && all_users_documents.length === 0 ? (
          <Loader />
        ) : all_users_documents.length === 0 ? (
          <Center bg={"#fff"} borderRadius={10}>
            <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
          </Center>
        ) : (
          <TableView
            headData={[
              "S.No.",
              "Document Name",
              "Document Type",
              "Expiry Date",
              "Document file",
              "Name",
              "Email",
              "Mobile",
            ]}
            body={mess_data.map((v, i) => (
              <Tr key={i}>
                <Td textAlign={"center"}>{i + initialValue}</Td>
                <Td textAlign={"center"}>{v.Name}</Td>
                <Td textAlign={"center"}>{v.doc_type}</Td>
                <Td textAlign={"center"}>
                  {Date.parse(v.expiry_date) ? v.expiry_date : ""}
                </Td>
                <Td textAlign={"center"}>
                  <Text
                    fontSize={"md"}
                    fontWeight={"semibold"}
                    cursor={"pointer"}
                    onClick={() => {
                      setUrl(v.document);
                      onViewOpen();
                    }}
                  >
                    View File
                  </Text>
                </Td>
                <Td textAlign={"center"}>{v.cname}</Td>
                <Td textAlign={"center"}>{v.Email}</Td>
                <Td textAlign={"center"}>{v.mobile}</Td>
              </Tr>
            ))}
            pagination={true}
            rows={rows}
            page={page}
            initialValue={initialValue}
            onChange={(v) => {
              setRows(v.target.value);
              search.length > 2 || (startDate.length != 0 && endDate.length != 0)
                ? store
                    .dispatch(
                      get_search_users_documents_list({
                        page: page,
                        rows: v.target.value,
                        value: value,
                        v: value == "docexp" ? startDate + "," + endDate : search,
                      })
                    )
                    .then(unwrapResult)
                    .then((v) =>
                      setMess_data(
                        v.message == "No Data available" ? [] : v.message
                      )
                    )
                : store
                    .dispatch(
                      get_all_users_documents_list({
                        page: 1,
                        rows: v.target.value,
                      })
                    )
                    .then(unwrapResult)
                    .then((v) =>
                      setMess_data(
                        v.message == "No Data available" ? [] : v.message
                      )
                    );
              setInitialValue(1);
              setpage(1);
            }}
            onPrevious={() => {
              initialValue == 1 ||
                setInitialValue(Number(initialValue) - Number(rows));
              page == 1 || setpage(page - 1);
              page === 1 ||
                (search.length > 2 ||
                (startDate.length != 0 && endDate.length != 0)
                  ? store
                      .dispatch(
                        get_search_users_documents_list({
                          page: page - 1,
                          rows: rows,
                          value: value,
                          v:
                            value == "docexp"
                              ? startDate + "," + endDate
                              : search,
                        })
                      )
                      .then(unwrapResult)
                      .then((v) =>
                        setMess_data(
                          v.message == "No Data available" ? [] : v.message
                        )
                      )
                  : store
                      .dispatch(
                        get_all_users_documents_list({
                          page: page - 1,
                          rows: rows,
                        })
                      )
                      .then(unwrapResult)
                      .then((v) => setMess_data(v.message)));
            }}
            onNext={() => {
              rows * page >= all_doc_count ||
                setInitialValue(Number(initialValue) + Number(rows));
              rows * page >= all_doc_count || setpage(page + 1);
              rows * page >= all_doc_count ||
                (search.length > 2 ||
                (startDate.length != 0 && endDate.length != 0)
                  ? store
                      .dispatch(
                        get_search_users_documents_list({
                          page: page + 1,
                          rows: rows,
                          value: value,
                          v:
                            value == "docexp"
                              ? startDate + "," + endDate
                              : search,
                        })
                      )
                      .then(unwrapResult)
                      .then((v) =>
                        setMess_data(
                          v.message == "No Data available" ? [] : v.message
                        )
                      )
                  : store
                      .dispatch(
                        get_all_users_documents_list({
                          page: page + 1,
                          rows: rows,
                        })
                      )
                      .then(unwrapResult)
                      .then((v) => setMess_data(v.message)));
            }}
            total_value={all_doc_count}
          />
        )}
        <Modal isOpen={isDateOpen}>
          <ModalOverlay />
          <ModalContent alignItems={"center"}>
            <ModalBody>
              <DateRange
                editableDateInputs={false}
                onChange={(item) => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
              />
            </ModalBody>
            <ModalFooter gap={5}>
              <CustomButton
                title={"Cancel"}
                bg={"#A9A9A9"}
                onClick={() => onDateClose()}
              />
              <CustomButton
                title={"Apply"}
                onClick={() => {
                  setStartDate(moment(state[0].startDate).format("YYYY-MM-DD"));
                  setEndDate(moment(state[0].endDate).format("YYYY-MM-DD"));
                  store
                    .dispatch(
                      get_search_users_documents_list({
                        page: page,
                        rows: rows,
                        value: value,
                        v:
                          moment(state[0].startDate).format("YYYY-MM-DD") +
                          "," +
                          moment(state[0].endDate).format("YYYY-MM-DD"),
                      })
                    )
                    .then(unwrapResult)
                    .then((v) =>
                      setMess_data(
                        v.message == "No Data available" ? [] : v.message
                      )
                    );
                  onDateClose();
                }}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>
        <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
      </Container>
    );
  };
  
  export default AllUsersDocuments;
  