import { toast } from "react-toastify";
import { ID, base_url } from "./utils";
import store from "../redux/store";
import { getClaims } from "../redux/slice/claimSlice";
import { getProviders } from "../redux/slice/providerSlice";
import { getPolicylist } from "../redux/slice/policylistSlice";
import {  get_background_img } from "../redux/slice/backgroundSlice";
import { get_video } from "../redux/slice/VideoSlice";
import { base_url_2, get_alerts } from "../redux/slice/alertsSlice";

export const userDetailById = async (id, setLoading) => {
  try {
    const body = new FormData();
    body.append("action", "listbyid");
    body.append("id", id);
    setLoading(true);
    const response = await fetch(base_url + "api/profile.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};
export const userPoliciesById = async (id, setLoading) => {
  try {
    const body = new FormData();
    body.append("action", "list");
    body.append("userid", id);
    // body.append("id", id);
    setLoading(true);
    const response = await fetch(base_url + "api/policy.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      return res;
    } else {
      // toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};
export const userDocumentsById = async (id, setLoading) => {
  try {
    const body = new FormData();
    body.append("action", "listforadmin");
    body.append("userid", id);
    // body.append("id", id);
    setLoading(true);
    const response = await fetch(base_url + "api/document.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      return res;
    } else {
      // toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const addUserPolicy = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/policy.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};
export const addUserDocument = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/document.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const sendNotification = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/create-notification.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const addUpdateClaim = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/claims.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(getClaims());
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const add_background_image = async (body, setLoading,type) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/image.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_background_img(type));
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const video_image = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/video.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_video());
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const alerts_api = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/alert-message.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_alerts());
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const provider_action = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/provider.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(getProviders());
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const policy_action = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/policy-list.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(getPolicylist());
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const login_api = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/adminlogin.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      localStorage.setItem("userId", JSON.stringify(res));
      window.location.reload();
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const update_limit = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/profile.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const message_action = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/contact.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};
export const user_policies_action = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/user-policies.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    setLoading(false);

    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};
export const update_status = async (id, status) => {
  try {
    const body = new FormData();
    body.append("action", "delete");
    body.append("id", id);
    body.append("status", status);
    const response = await fetch(base_url + "api/profile.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    return res;
  } catch (error) {}
};

export const bulk_upload = async (id, file, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", id);
    body.append("addedby", ID);
    body.append("file", file);
    const response = await fetch(base_url + "api/bulk.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const employee_action = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "api/employee.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const get_provider_list = async (type, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("action", "listbytype");
    body.append("instype", type);
    const response = await fetch(base_url + "api/provider.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
