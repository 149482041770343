import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
import store from "../store";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const getDocuments_list = createAsyncThunk("documents", async (id) => {
  const body = new FormData();
  body.append("action", "listforadmin");
  // body.append("userid", user.userid);
  body.append("userid", id);
  const response = await fetch(base_url + "api/document.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();

  return res;
});
export const get_all_users_documents_list = createAsyncThunk(
  "alldocuments",
  async (data) => {
    const body = new FormData();
    body.append("action", "list");
    // body.append("userid", user.userid);
    body.append("page", data.page);
    body.append("limit", data.rows);
    const response = await fetch(base_url + "api/user-documents.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    store.dispatch(get_all_users_documents(res.totalcount));
    return res;
  }
);
export const get_search_users_documents_list = createAsyncThunk(
  "searchdocuments",
  async (data) => {
    const body = new FormData();
    body.append("action", "filter");
    body.append("page", data.page);
    body.append("limit", data.rows);
    body.append("filter", data.value);
    body.append("filterVal", data.v);
    const response = await fetch(base_url + "api/user-documents.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);

export const get_all_users_documents = createAsyncThunk(
  "alldocumentslist",
  async (limit) => {
    const body = new FormData();
    body.append("action", "list");
    body.append("page", 1);
    body.append("limit", limit);
    const response = await fetch(base_url + "api/user-documents.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    return res;
  }
);
const documentSlice = createSlice({
  name: "documents",
  initialState: {
    documents_list: [],
    d_loading: false,
    all_users_documents: [],
    all_users_documents_list: [],
    all_doc_loading: false,
    all_doc_count: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(getDocuments_list.pending, (state, action) => {
      state.d_loading = true;
    });
    builder.addCase(getDocuments_list.rejected, (state, action) => {
      state.d_loading = false;
    });

    builder.addCase(getDocuments_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.d_loading = false;
      if (data.success) {
        state.documents_list = data.message;
      } else {
        state.documents_list = [];
      }
    });

    builder.addCase(get_all_users_documents_list.pending, (state, action) => {
      state.all_doc_loading = true;
    });
    builder.addCase(get_all_users_documents_list.rejected, (state, action) => {
      state.all_doc_loading = false;
    });

    builder.addCase(get_all_users_documents_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.all_doc_loading = false;
      if (data.success) {
        state.all_users_documents = data.message;
        state.all_doc_count = data.totalcount;
      } else {
        state.all_users_documents = [];
      }
    });

    builder.addCase(
      get_search_users_documents_list.pending,
      (state, action) => {
        state.all_doc_loading = true;
      }
    );
    builder.addCase(
      get_search_users_documents_list.rejected,
      (state, action) => {
        state.all_doc_loading = false;
      }
    );

    builder.addCase(
      get_search_users_documents_list.fulfilled,
      (state, action) => {
        const data = action.payload;
        state.all_doc_loading = false;
        if (data.success) {
          state.all_users_documents = data.message;
          state.all_doc_count = data.totalcount;
        } else {
          state.all_users_documents = [];
        }
      }
    );

    builder.addCase(get_all_users_documents.fulfilled, (state, action) => {
      const data = action.payload;
      state.all_doc_loading = false;
      if (data.success) {
        state.all_users_documents_list = data.message;
      } else {
        state.all_users_documents = [];
      }
    });
  },
});

export default documentSlice.reducer;
