import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableView from "../../components/TableView";
import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Spacer,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { getNotificationlist } from "../../redux/slice/notificationSlice";
import CustomButton from "../../components/CustomButton";
import CustomToolTip from "../../components/CustomTooltip";

const NotificationList = () => {
  const navigate = useNavigate();
  const { notification_list, loading, total_notification } = useSelector(
    (state) => state.notifications
  );
  const [initialValue, setInitialValue] = useState(1);
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  useEffect(() => {
    store.dispatch(
      getNotificationlist({ id: user.userid, rows: rows, page: page })
    );
  }, []);
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Notification List</Heading>
        <CustomButton
          title={"Add Notification"}
          onClick={() => navigate("/notifications/add-notification")}
        />
      </Flex>
      <Flex gap={2} align={"center"}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Notification:
        </Text>
        <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
          {total_notification}
        </Text>
      </Flex>
      <Spacer h={3} />
      {loading && notification_list.length === 0 ? (
        <Loader />
      ) : notification_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          headData={["S.No.", "Title", "Message", "scheduledate"]}
          body={notification_list?.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + initialValue}</Td>
              <Td textAlign={"center"}>{v.title}</Td>
              <Td textAlign={"center"}>
                {
                  <CustomToolTip
                    button={
                      <Text cursor={"pointer"} maxW={"xs"} overflow={"hidden"}>
                        {v.message}
                      </Text>
                    }
                    mess={v.message}
                  />
                }
              </Td>

              <Td textAlign={"center"}>
                {Date.parse(v.scheduledate) ? v.scheduledate : v.datetime}
              </Td>
            </Tr>
          ))}
          pagination={true}
          rows={rows}
          page={page}
          initialValue={initialValue}
          onChange={(v) => {
            setRows(v.target.value);
            store.dispatch(
              getNotificationlist({ page: 1, rows: v.target.value })
            );
            // .then(unwrapResult)
            // .then((v) => setMess_data(v.message));
            setInitialValue(1);
            setpage(1);
          }}
          onPrevious={() => {
            initialValue == 1 ||
              setInitialValue(Number(initialValue) - Number(rows));
            page == 1 || setpage(page - 1);
            page === 1 ||
              store.dispatch(
                getNotificationlist({ page: page - 1, rows: rows })
              );
            // .then(unwrapResult)
            // .then((v) => setMess_data(v.message));
          }}
          onNext={() => {
            rows * page >= total_notification ||
              setInitialValue(Number(initialValue) + Number(rows));
            rows * page >= total_notification || setpage(page + 1);
            rows * page >= total_notification ||
              store.dispatch(
                getNotificationlist({ page: page + 1, rows: rows })
              );
            // .then(unwrapResult)
            // .then((v) => setMess_data(v.message));
          }}
          total_value={total_notification}
        />
      )}
    </Container>
  );
};

export default NotificationList;
