import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
import store from "../store";
import { toast } from "react-toastify";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const base_url_2 = "https://digi.pmpframe.com/";

export const get_alerts = createAsyncThunk("alerts", async (type) => {
  const body = new FormData();
  body.append("action", "list");

  const response = await fetch(base_url + "api/alert-message.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

export const delete_alerts = createAsyncThunk("delalerts", async (data) => {
  const body = new FormData();
  body.append("action", "delete");
  // body.append("id", data?.id);
  
  data?.id?.forEach((item) => {
    body.append("id[]", item);
  });
  const response = await fetch(base_url + "api/alert-message.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    alerts_list: [],
    alerts_loading: false,
    delete_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(get_alerts.pending, (state, action) => {
      state.alerts_loading = true;
    });
    builder.addCase(get_alerts.rejected, (state, action) => {
      state.alerts_loading = false;
    });
    builder.addCase(delete_alerts.pending, (state, action) => {
      state.delete_loading = true;
    });
    builder.addCase(delete_alerts.rejected, (state, action) => {
      state.delete_loading = false;
    });
    builder.addCase(delete_alerts.fulfilled, (state, action) => {
      state.delete_loading = false;
      const data = action.payload;
      if (data.success) {
        toast.success(data?.message);
      } else {
        toast.success(data?.message);
      }
      //   store.dispatch(getClaims());
    });

    builder.addCase(get_alerts.fulfilled, (state, action) => {
      const data = action.payload;
      state.alerts_loading = false;
      if (data.success) {
        state.alerts_list = data.data;
      } else {
        state.alerts_list = [];
      }
    });
  },
});

export default alertsSlice.reducer;
