import {
  Button,
  Card,
  Center,
  Container,
  Flex,
  FormLabel,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Spacer,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  addUserDocument,
  addUserPolicy,
  update_limit,
  update_status,
  userDetailById,
  userDocumentsById,
  userPoliciesById,
} from "../../utils/apis";
import { useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import TitleValue from "../../components/TitleValue";
import ImageModal from "../../components/ImageModal";
import Loader from "../../components/Loader";
import CustomButton from "../../components/CustomButton";
import { BiArrowBack } from "react-icons/bi";
import { toast } from "react-toastify";
import { FiEdit, FiEdit3, FiTrash2 } from "react-icons/fi";
import CustomToolTip from "../../components/CustomTooltip";
import CustomPopup from "../../components/CustomPopup";
import SelectImage from "../../components/SelectImage";
import { bulk_upload } from "../../utils/apis";
import { IoCloseSharp } from "react-icons/io5";
import { decryptData, isValidEmail } from "../../utils/utils";

const UserDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDOpen,
    onOpen: onDOpen,
    onClose: onDClose,
  } = useDisclosure();
  const {
    isOpen: isPOpen,
    onOpen: onPOpen,
    onClose: onPClose,
  } = useDisclosure();
  const {
    isOpen: isBOpen,
    onOpen: onBOpen,
    onClose: onBClose,
  } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [detail, setDetail] = useState({});
  const [policyList, setPolicyList] = useState([]);
  const [DocumentsList, setDocumentsList] = useState([]);
  const [showList, setShowList] = useState("");
  const [url, setUrl] = useState("");
  const [edit_id1, setEdit_id1] = useState(false);
  const [edit_id2, setEdit_id2] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const [bulk_file, setBulk_file] = useState({});

  useEffect(() => {
    userDetailById(location.state, setLoading).then((v) => {
      v.success && setDetail(v.message);
    });
    userPoliciesById(location.state, setLoading1).then((v) => {
      v.success && setPolicyList(v.message);
    });
    userDocumentsById(location.state, setLoading2).then((v) => {
      v.success && setDocumentsList(v.message);
    });
  }, []);

  const policy_limit = () => {
    if (detail.policylimit.length === 0) {
      toast.info("Please enter a policy limit");
      return;
    }
    const body = new FormData();
    body.append("action", "limitupdate");
    body.append("policylimit", detail.policylimit);
    body.append("userid", location.state);
    update_limit(body, setLoading3).then(
      (v) =>
        v.success &&
        userDetailById(location.state, setLoading).then((v) => {
          v.success && setDetail(v.message);
          v.success && setEdit_id1(false);
        })
    );
  };
  const document_limit = () => {
    if (detail.doclimit.length === 0) {
      toast.info("Please enter a policy limit");
      return;
    }
    const body = new FormData();
    body.append("action", "limitupdate");
    body.append("doclimit", detail.doclimit);
    body.append("userid", location.state);
    update_limit(body, setLoading4).then(
      (v) =>
        v.success &&
        userDetailById(location.state, setLoading).then((v) => {
          v.success && setDetail(v.message);
          v.success && setEdit_id2(false);
        })
    );
  };
  const delete_policy = () => {
    onPClose();
    const body = new FormData();
    body.append("action", "delete");
    body.append("userid", location.state);
    body.append("id", id);
    addUserPolicy(body, setLoading).then(
      (v) =>
        v.success &&
        userPoliciesById(location.state, setLoading1).then((v) => {
          v.success && setPolicyList(v.message);
        })
    );
  };
  const delete_document = () => {
    onDClose();
    const body = new FormData();
    body.append("action", "delete");
    body.append("userid", location.state);
    body.append("id", id);
    addUserDocument(body, setLoading).then(
      (v) =>
        v.success &&
        userDocumentsById(location.state, setLoading2).then((v) => {
          v.success && setDocumentsList(v.message);
        })
    );
  };
  const update_user = () => {
    if (
      detail.Name.length === 0 ||
      detail.Email.length === 0 ||
      detail.mobile.length === 0
    ) {
      toast.info("Please fill all fields");
      return;
    }
    if (detail.mobile.length < 10) {
      toast.info("invailid mobile number");
      return;
    }
    if (!isValidEmail.test(detail.Email)) {
      toast.info("invailid email");
      return;
    }
    if (detail.mobile1.length != 0 && detail.mobile1.length < 10) {
      toast.info("invailid mobile1 number");
      return;
    }
    if (detail.mobile2.length != 0 && detail.mobile2.length < 10) {
      toast.info("invailid mobile2 number");
      return;
    }
    if (detail.mobile1.length != 0 || detail.mobile2.length != 0) {
      if (
        detail.mobile == detail.mobile1 ||
        detail.mobile1 == detail.mobile2 ||
        detail.mobile2 == detail.mobile
      ) {
        toast.info("same");
        return;
      }
    }
    const body = new FormData();
    body.append("action", "adminupdate");
    body.append("name", detail.Name);
    body.append("email", detail.Email);
    body.append("mobile", detail.mobile);
    body.append("mobile1", detail.mobile1);
    body.append("mobile2", detail.mobile2);
    body.append("userid", location.state);
    update_limit(body, setLoading1).then(
      (v) =>
        v.success &&
        userDetailById(location.state, setLoading).then((v) => {
          v.success && setDetail(v.message);
          v.success && setEdit(false);
        })
    );
  };
  const uploadBulk = () => {
    if (!bulk_file?.name) {
      toast.info("please select a file");
      return;
    }
    bulk_upload(location.state, bulk_file, setLoading2).then((v) => {
      if (v.success) {
        toast.success(v.message);
        userPoliciesById(location.state, setLoading1).then((v) => {
          v.success && setPolicyList(v.message);
        });
        onBClose();
      } else {
        toast.error(v.message);
      }
    });
  };
  const downloadImage = async (originalImage) => {
    return (
      <Link
        href="https://digi.insugo.in/policy.xlsx"
        download={true}
        target="_blank"
        isExternal
      />
    );
    // const originalImage =
    //   "https://cdn1.iconfinder.com/data/icons/ninja-things-1/1772/ninja-simple-512.png";
    // const originalImage =
    //   "https://insugo.vensframe.com/api/documents/Nataraju-0JEk8L8WrD1r.png";
    // const image = await fetch("https://digi.insugo.in/policy.xlsx");

    // //Split image name
    // const nameSplit = originalImage.split("/");
    // const duplicateName = nameSplit.pop();

    // const imageBlog = await image.blob();
    // const imageURL = URL.createObjectURL(imageBlog);
    // const link = document.createElement("a");
    // link.href = imageURL;
    // link.download = "" + duplicateName + "";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>User Detail</Heading>
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Spacer h={3} />
      {loading ? (
        <Loader />
      ) : (
        <Card p={5} borderRadius={10} shadow={"base"}>
          <Flex justify={"end"} gap={5}>
            <CustomButton
              title={edit ? "Cancel" : "Edit"}
              bg={edit && "#727b82"}
              icon={!edit ? <FiEdit /> : <IoCloseSharp size={20} />}
              onClick={() => setEdit(!edit)}
            />
            <Flex alignItems={"center"}>
              <FormLabel htmlFor="email-alerts" mb="0">
                Block
              </FormLabel>
              <Switch
                id="email-alerts"
                defaultChecked={detail.status == 0 ? false : true}
                onChange={(v) => {
                  setDetail({ ...detail, status: detail.status == 0 ? 2 : 0 });
                  update_status(detail.id, detail.status == 0 ? 2 : 0).then(
                    (v) => {
                      if (v.success) {
                        toast.success(v.message);
                      } else {
                        toast.error(v.message);
                      }
                    }
                  );
                }}
              />
            </Flex>
          </Flex>
          <CustomInput
            label1={edit ? "Name*" : "Name"}
            label2={edit ? "Email*" : "Email"}
            value1={detail.Name}
            value2={detail.Email}
            onChange1={(e) => setDetail({ ...detail, Name: e.target.value })}
            onChange2={(e) => setDetail({ ...detail, Email: e.target.value })}
            textonly1={!edit}
            textonly2={!edit}
          />
          <CustomInput
            label1={edit ? "Mobile*" : "Mobile"}
            label2={"Date Time"}
            value1={detail.mobile}
            value2={detail.datetime}
            onChange1={(e) =>
              e.target.value.length < 11 &&
              setDetail({
                ...detail,
                mobile: e.target.value.replace(/\D/g, ""),
              })
            }
            textonly1={!edit}
            textonly2
          />
          {/* {detail?.mobile1?.length == 0 || ( */}
          <CustomInput
            label1={"Mobile1"}
            label2={"Mobile2"}
            // single={detail?.mobile2?.length == 0 ? true : false}
            placeholder1={"Enter Mobile1"}
            placeholder2={"Enter mobile2"}
            value1={detail.mobile1}
            value2={detail.mobile2}
            onChange1={(e) =>
              e.target.value.length < 11 &&
              setDetail({
                ...detail,
                mobile1: e.target.value.replace(/\D/g, ""),
              })
            }
            onChange2={(e) =>
              e.target.value.length < 11 &&
              setDetail({
                ...detail,
                mobile2: e.target.value.replace(/\D/g, ""),
              })
            }
            textonly1={!edit}
            textonly2={!edit}
          />
          {/* )} */}

          {edit && (
            <Center mt={5}>
              <CustomButton
                title={"Update"}
                onClick={() => update_user()}
                loading={loading1}
              />
            </Center>
          )}
          <CustomInput
            textonly1={!edit_id1}
            textonly2={!edit_id2}
            label1={"Policy limit"}
            label2={"Document limit"}
            value1={detail.policylimit}
            value2={detail.doclimit}
            onChange1={(e) => {
              setDetail({
                ...detail,
                policylimit: e.target.value.replace(/([a-zA-Z ])/g, ""),
              });
            }}
            onChange2={(e) => {
              setDetail({
                ...detail,
                doclimit: e.target.value.replace(/([a-zA-Z ])/g, ""),
              });
            }}
            rightButton1={
              <Center>
                {!edit_id1 ? (
                  <FiEdit
                    cursor={"pointer"}
                    onClick={() => setEdit_id1(true)}
                  />
                ) : (
                  <CustomButton
                    title={"Update"}
                    onClick={() => policy_limit()}
                    loading={loading3}
                  />
                )}
              </Center>
            }
            rightButton2={
              <Center>
                {!edit_id2 ? (
                  <FiEdit
                    cursor={"pointer"}
                    onClick={() => setEdit_id2(true)}
                  />
                ) : (
                  <CustomButton
                    title={"Update"}
                    onClick={() => document_limit()}
                    loading={loading4}
                  />
                )}
              </Center>
            }
          />

          <Flex
            bg={"#00000004"}
            p={2}
            mt={3}
            borderRadius={5}
            alignItems={"center"}
            gap={5}
          >
            <Heading fontSize={"lg"} flex={1}>
              Policy List
            </Heading>
            <Button
              alignSelf={"flex-start"}
              h={8}
              onClick={() => onBOpen()}
              variant={"link"}
              color={"#000"}
            >
              Bulk Upload
            </Button>
            <Button
              alignSelf={"flex-start"}
              h={8}
              onClick={() => setShowList(1)}
              variant={"link"}
              color={"#000"}
            >
              Show Policy
            </Button>
            <Button
              alignSelf={"flex-start"}
              h={8}
              onClick={() => navigate("add-policy", { state: location.state })}
              variant={"link"}
              color={"#000"}
            >
              Add Policy
            </Button>
          </Flex>
          {showList == 1 && (
            <SimpleGrid minChildWidth={"sm"} gap={5} mt={4}>
              {policyList.length == 0 ? (
                <Text>No Policy data</Text>
              ) : (
                policyList.map((v, i) => (
                  <Stack
                    key={i}
                    w={"full"}
                    border={"1px solid #00000033"}
                    borderRadius={5}
                    px={5}
                    pb={3}
                  >
                    <Flex gap={5} justify={"center"} marginTop={2}>
                      <TitleValue title={"Name"} value={v.name} />
                      <CustomToolTip
                        button={
                          <FiEdit3
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              navigate("edit-policy", { state: v });
                            }}
                          />
                        }
                        mess={"Edit Policy"}
                      />

                      <CustomToolTip
                        button={
                          <FiTrash2
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              setId(v.id);
                              onPOpen();
                            }}
                          />
                        }
                        mess={"Delete Policy"}
                      />
                    </Flex>
                    <TitleValue
                      title={"Policy Company"}
                      value={v.policy_company}
                    />
                    <TitleValue title={"Policy Plan"} value={v.policy_plan} />
                    <TitleValue
                      title={"Policy Number"}
                      value={v.policy_number}
                    />
                    <TitleValue
                      title={"Insurance Type"}
                      value={v.insurance_type}
                    />
                    <TitleValue title={"Expiry Date"} value={v.expiry_date} />
                    <TitleValue
                      title={"Policy File"}
                      value={
                        <Button
                          variant={"link"}
                          color={"#"}
                          onClick={() => {
                            // setUrl(v.policy_file);
                            setUrl(decryptData(v.policy_file));
                            onOpen();
                          }}
                        >
                          View File
                        </Button>
                      }
                    />
                  </Stack>
                ))
              )}
            </SimpleGrid>
          )}
          <Flex
            bg={"#00000004"}
            p={2}
            mt={3}
            borderRadius={5}
            alignItems={"center"}
            gap={5}
          >
            <Heading fontSize={"lg"} flex={1}>
              Documents List
            </Heading>

            <Button
              alignSelf={"flex-start"}
              h={8}
              onClick={() => setShowList(2)}
              variant={"link"}
              color={"#000"}
            >
              Show Document
            </Button>
            <Button
              alignSelf={"flex-start"}
              h={8}
              onClick={() =>
                navigate("add-document", { state: location.state })
              }
              variant={"link"}
              color={"#000"}
            >
              Add Document
            </Button>
          </Flex>
          {showList == 2 && (
            <SimpleGrid
              minChildWidth={"sm"}
              // maxInlineSize={"sm"}
              gap={5}
              mt={4}
            >
              {DocumentsList.length == 0 ? (
                <Text>No Documents data</Text>
              ) : (
                DocumentsList.map((v, i) => (
                  <Stack
                    key={i}
                    w={"full"}
                    border={"1px solid #00000033"}
                    borderRadius={5}
                    px={5}
                    pb={3}
                    // maxW={"lg"}
                  >
                    <Flex gap={5} justify={"center"} marginTop={2}>
                      <TitleValue title={"Name"} value={v.Name} />

                      <CustomToolTip
                        button={
                          <FiEdit3
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              navigate("edit-document", { state: v });
                            }}
                          />
                        }
                        mess={"Edit Document"}
                      />

                      <CustomToolTip
                        button={
                          <FiTrash2
                            size={20}
                            cursor={"pointer"}
                            onClick={() => {
                              setId(v.id);
                              onDOpen();
                            }}
                          />
                        }
                        mess={"Delete Document"}
                      />
                    </Flex>
                    <TitleValue title={"doc_type"} value={v.doc_type} />
                    {Date.parse(v.expiry_date) && (
                      <TitleValue title={"Expiry Date"} value={v.expiry_date} />
                    )}

                    <Text>Document Image</Text>
                    {decryptData(v.document)?.split(".").pop().trim() ==
                    "pdf" ? (
                      <iframe
                        src={decryptData(v.document)}
                        style={{ width: "100%", height: "100%" }}
                        frameborder="1"
                        loading="eager"
                        onLoadStart={() => setLoading(true)}
                        onLoadedData={() => setLoading(false)}
                      ></iframe>
                    ) : (
                      <Image
                        src={decryptData(v.document)}
                        h={"13rem"}
                        w={"13rem"}
                        objectFit={"contain"}
                      />
                    )}
                  </Stack>
                ))
              )}
            </SimpleGrid>
          )}
        </Card>
      )}
      <ImageModal isOpen={isOpen} onClose={onClose} url={url} />

      <CustomPopup
        mess={"Are you sure? you want to delete Policy"}
        isOpen={isPOpen}
        onClick={delete_policy}
        onClose={onPClose}
      />

      <CustomPopup
        title={"ADD Policy"}
        mess={
          <>
            <Text>
              Download the excel template and fill your team member data.
            </Text>
            <Link
              href="https://digi.insugo.in/policy.xlsx"
              download={true}
              target="_blank"
              isExternal={false}
              textDecorationLine={"none"}
              color={"blue.500"}
              fontWeight={"bold"}
              alignSelf={"center"}
              my={5}
            >
              Download File
            </Link>
            <SelectImage
              doctype={".xlsx, .xls"}
              label={"Select File"}
              filename={bulk_file?.name}
              onChange={(e) => setBulk_file(e.target.files[0])}
            />
          </>
        }
        isOpen={isBOpen}
        onClick={uploadBulk}
        onClose={onBClose}
        b_color={"green"}
        b_name={"Upload"}
      />
      <CustomPopup
        mess={"Are you sure? you want to delete Document"}
        isOpen={isDOpen}
        onClick={delete_document}
        onClose={onDClose}
      />
    </Container>
  );
};

// const UserDetail = () => {
//   return <Outlet />;
// };
export default UserDetail;
