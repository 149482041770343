import {
  Button,
  Card,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { CSVLink } from "react-csv";
import { FiFilter } from "react-icons/fi";

const TableHeader = ({
  onPdfClick,
  data = [],
  onChange,
  value,
  onSerchChange,
  onCsvClick,
  CSVdata = [],
  headers = [],
  filter,
  filename,
  options,
  date_range,
  placeholder,
}) => {
  return (
    <Card
      p={3}
      mb={2}
      flexDirection={["column", "row"]}
      alignItems={["start", "center"]}
      gap={1}
    >
      {/* <CustomButton title={"Export"} onClick={onClick} /> */}
      <Popover>
        <PopoverTrigger>
          <Button
            color={"#fff"}
            bg={"#FFBF00"}
            _hover={{ bg: "#FFBF0099" }}
            fontWeight={"bold"}
            h={8}
          >
            Export
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w={"150px"}>
            <PopoverArrow />
            <PopoverBody>
              <Stack align={"flex-start"}>
                <Button variant={"link"} onClick={onPdfClick}>
                  PDF
                </Button>
                <CSVLink data={CSVdata} filename={filename} headers={headers}>
                  <Button variant={"link"}>Excel/CSV</Button>
                </CSVLink>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      <Spacer />
      {filter || (
        <Select
          icon={<FiFilter />}
          // placeholder="All"
          w={"40"}
          borderColor={"#000"}
          onChange={onChange}
        >
          {options
            ? options
            : data.map((v, i) => <option value={v}>{v}</option>)}
        </Select>
      )}
      <Spacer maxW={1} />
      {date_range || (
        <Input
          placeholder={placeholder || "Search user..."}
          w={"80"}
          value={value}
          onChange={onSerchChange}
        />
      )}
    </Card>
  );
};

export default TableHeader;
