import {
  Card,
  Center,
  Container,
  Flex,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import HeadingText from "../../components/HeadingText";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { addUpdateClaim } from "../../utils/apis";
import { BiArrowBack } from "react-icons/bi";
import { ID, isValidEmail } from "../../utils/utils";

const EditClaim = () => {
  const [isLarger] = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();
  const location = useLocation();
  const [claim_data, setClaim_data] = useState({});
  const [loading, setLoading] = useState(false);
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);

  useEffect(() => {
    setClaim_data(location.state);
  }, []);

  const validation = () => {
    if (
      !claim_data?.insu_company ||
      !claim_data.contact ||
      // !claim_data.healthurl ||
      // !claim_data.motorurl ||
      // !claim_data.email ||
      (claim_data?.email && !isValidEmail.test(claim_data.email))
    ) {
      setClaim_data({
        ...claim_data,
        insu_companyval: !claim_data?.insu_company ? true : false,
        insu_companymess: !claim_data?.insu_company
          ? "Insurance Company is required"
          : "",
        contactval: !claim_data.contact ? true : false,
        contactmess: !claim_data.contact ? "Contact is required" : "",
        // healthurlval: !claim_data.healthurl ? true : false,
        // healthurlmess: !claim_data.healthurl
        //   ? "Health Insurance Url is required"
        //   : "",
        // motorurlval: !claim_data.motorurl ? true : false,
        // motorurlmess: !claim_data.motorurl
        //   ? "Motor Insurance Url is required"
        //   : "",
        emailval:
          claim_data?.email && !isValidEmail.test(claim_data.email)
            ? true
            : false,
        emailmess:
          claim_data?.email && !isValidEmail.test(claim_data.email)
            ? "Invalid Email address"
            : "",
      });
      return;
    } else {
      update_claim();
    }
  };
  const update_claim = () => {
    const body = new FormData();
    body.append("action", "update");
    body.append("inscomp", claim_data.insu_company);
    body.append("contact", claim_data.contact);
    body.append("email", claim_data.email);
    body.append("healthurl", claim_data.healthurl);
    body.append("motorurl", claim_data.motorurl);
    claim_data?.image?.name && body.append("logo", claim_data.image);
    claim_data?.health?.name && body.append("healthfile", claim_data.health);
    claim_data?.motor?.name && body.append("motorfile", claim_data.motor);

    body.append("userid", user.userid);
    body.append("id", claim_data.id);
    addUpdateClaim(body, setLoading).then((v) => v.success && navigate(-1));
  };
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        validation();
      }
    },
    [claim_data]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <HeadingText title={"Edit Claim"} />
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        ></CustomButton>
      </Flex>
      <Card bg={"#fff"} mt={2} p={5}>
        <CustomInput
          label1={"Insurance Company"}
          placeholder1={"Enter Insurance Company"}
          value1={claim_data.insu_company}
          onChange1={(e) =>
            setClaim_data({
              ...claim_data,
              insu_company: e.target.value,
              insu_companyval: false,
              insu_companymess: "",
            })
          }
          errorBorder1={claim_data.insu_companyval}
          error1={claim_data.insu_companymess}
          label2={"Contact"}
          placeholder2={"Enter Contact"}
          value2={claim_data.contact}
          onChange2={(e) =>
            setClaim_data({
              ...claim_data,
              contact: e.target.value.replace(/\D/g, ""),
              contactmess: "",
              contactval: false,
            })
          }
          errorBorder2={claim_data.contactval}
          error2={claim_data.contactmess}
        />

        <Flex gap={3} flexDirection={isLarger ? "row" : "column"}>
          <CustomInput
            label1={"Email"}
            placeholder1={"Enter Email"}
            value1={claim_data.email}
            onChange1={(e) =>
              setClaim_data({
                ...claim_data,
                email: e.target.value,
                emailmess: "",
                emailval: false,
              })
            }
            onlyone
            errorBorder1={claim_data.emailval}
            error1={claim_data.emailmess}
          />
          <SelectImage
            mt={2}
            label={"Logo"}
            filename={claim_data?.image?.name}
            onChange={(e) =>
              setClaim_data({ ...claim_data, image: e.target.files[0] })
            }
          />
        </Flex>

        <Flex gap={3} flexDirection={isLarger ? "row" : "column"}>
          <SelectImage
            mt={2}
            label={"Health Insurance document"}
            filename={claim_data?.health?.name}
            onChange={(e) =>
              setClaim_data({ ...claim_data, health: e.target.files[0] })
            }
          />
          <CustomInput
            label1={"Health Insurance Url"}
            placeholder1={"Enter Health Insurance Url"}
            value1={claim_data.healthurl}
            onChange1={(e) =>
              setClaim_data({
                ...claim_data,
                healthurl: e.target.value,
                healthurlval: false,
                healthurlmess: "",
              })
            }
            onlyone
            errorBorder1={claim_data.healthurlval}
            error1={claim_data.healthurlmess}
          />
        </Flex>

        <Flex gap={3} flexDirection={isLarger ? "row" : "column"}>
          <SelectImage
            mt={2}
            label={"Motor Insurance document"}
            filename={claim_data?.motor?.name}
            onChange={(e) =>
              setClaim_data({ ...claim_data, motor: e.target.files[0] })
            }
          />
          <CustomInput
            label1={"Motor Insurance Url"}
            placeholder1={"Enter Motor Insurance Url"}
            value1={claim_data.motorurl}
            onChange1={(e) =>
              setClaim_data({
                ...claim_data,
                motorurl: e.target.value,
                motorurlval: false,
                motorurlmess: "",
              })
            }
            onlyone
            errorBorder1={claim_data.motorurlval}
            error1={claim_data.motorurlmess}
          />
        </Flex>

        <Center mt={3}>
          <CustomButton
            title={"Update"}
            loading={loading}
            onClick={() => validation()}
          />
        </Center>
      </Card>
    </Container>
  );
};

export default EditClaim;
