import { createSlice } from "@reduxjs/toolkit";

const otherSlice = createSlice({
  name: "other",
  initialState: {
    sidebar_width: true,
  },
  reducers: {
    fullSliderwidth(state) {
      state.sidebar_width = !state.sidebar_width;
    },
  },
});

export const { fullSliderwidth } = otherSlice.actions;
export default otherSlice.reducer;
