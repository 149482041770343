import { Base64 } from "js-base64";
import jsPDF from "jspdf";
import "jspdf-autotable";
// export const base_url = "https://insugo.vensframe.com/";
// export const base_url = "https://digi.insugo.in/";
export const base_url = "https://digi.pmpframe.com/";

const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const ID = user !== null ? user?.userid : "";

export const isValidEmail = /\S+@\S+\.\S+/;
// export const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const exportPDF = ({
  table_headers,
  table_data,
  tableName,
  fileName,
}) => {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = { tableName };
  //   const title = "Insugo";
  //   const headers = [
  //     ["S.No.", "Name", "Email", "Mobile", "Platform", "Policies", "Document"],
  //   ];
  const headers = [table_headers];
  const data = table_data;
  //   const data = users_list.map((elt, i) => [
  //     i + initialValue,
  //     elt.Name,
  //     elt.Email,
  //     elt.mobile,
  //     elt.platform,
  //     elt.totalpolicies,
  //     elt.totaldocument,
  //   ]);

  let content = {
    startY: 50,
    head: headers,
    body: data,
  };

  doc.text(tableName, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${fileName}.pdf`);
};

export const encryptData = (data) => {
  const randomChars = Array.from(
    { length: 2 },
    () => String.fromCharCode(Math.floor(Math.random() * 26) + 97) // Generates random lowercase letters
  ).join("");
  const randomChars1 = Array.from(
    { length: 2 },
    () => String.fromCharCode(Math.floor(Math.random() * 26) + 97) // Generates random lowercase letters
  ).join("");
  const encryptedData = Base64.encode(data);

  return randomChars + encryptedData + randomChars1;
};
export const decryptData = (str = "") => {
  if (str.length < 4) {
    // The string is too short to remove 2 characters from both the start and end.
    return str;
  }

  // Remove 2 characters from the start and end
  const result = str.substring(2, str.length - 2);
  const decryptedData = Base64.decode(result);
  return decryptedData;
};
