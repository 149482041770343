import { Button, Flex, Heading } from "@chakra-ui/react";
import React from "react";

const HeadingText = ({ title, name }) => {
  return (
    <Flex width={"full"} justify={"space-between"}>
      <Heading fontSize={"xl"}>{title}</Heading>
      {name && <Button variant={"link"}>{name}</Button>}
    </Flex>
  );
};

export default HeadingText;
