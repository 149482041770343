import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Dashboard from "./screens/dashboard";
import Claims from "./screens/claims";
import AddClaim from "./screens/claims/AddClaim";
import EditClaim from "./screens/claims/EditClaim";
import UserDetail from "./screens/users/UserDetail";
import UsersList from "./screens/users/UserList";
import AddPolicy from "./screens/users/AddPolicy";
import AddDocument from "./screens/users/AddDocument";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./screens/auth/Login";
import Messages from "./screens/messages";
import NotificationList from "./screens/notification";
import AddNotification from "./screens/notification/AddNotification";
import PolicyProvider from "./screens/provider";
import PolicyList from "./screens/policies";
import AddProvider from "./screens/provider/AddProvider";
import AddNewPolicy from "./screens/policies/AddNewPolicy";
import EditDocument from "./screens/users/EditDocument";
import EditPolicy from "./screens/users/EditPolicy";
import AllUserList from "./screens/alluserpolicy";
import EmployeeList from "./screens/employee";
import AddEmployee from "./screens/employee/AddEmployee";
import EditEmployee from "./screens/employee/EditEmployee";
import EditProvider from "./screens/provider/EditProvider";
import { Heading, Text } from "@chakra-ui/react";
import AllUsersDocuments from "./screens/allusersdocuments";
import { useEffect, useRef, useState } from "react";
import CustomPopup from "./components/CustomPopup";
import Videos from "./screens/Others/Videos";
import BackgroundImg from "./screens/Others/BackgroundImgWeb";
import Layout2 from "./components/Layout2";
import BackgroundImgApp from "./screens/Others/BackgroundImgApp";
import AddBackground from "./screens/Others/AddBackground";
import AddVideo from "./screens/Others/AddVideo";
import AlertsComp from "./screens/Others/AlertsComp";
import AddAlerts from "./screens/Others/AddAlerts";

function App() {
  const [sessionExpire, setSessionExpire] = useState(false);
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  useEffect(() => {
    if (user === null) {
      user == null && navigate("/login");
      return;
    }
    navigate("/");
  }, []);
  const [timeout, setTimeout] = useState(600);
  const timerRef = useRef(null);

  const resetTimer = () => {
    clearInterval(timerRef.current);
    setTimeout(600);
    startTimer();
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeout((prev) => prev - 1);
    }, 1000);
  };

  useEffect(() => {
    const handleUserActivity = () => {
      resetTimer();
    };
    startTimer();
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("mousemove", handleUserActivity);
    return () => {
      document.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("mousemove", handleUserActivity);
      clearInterval(timerRef.current);
    };
  }, []);
  useEffect(() => {
    if (timeout === 0) {
      if (user != null) {
        setSessionExpire(true);
        localStorage.clear();
      }
    }
  }, [timeout]);
  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute />}>
          {user?.role == "superadmin" && (
            <Route path="/" element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="claims" element={<Outlet />}>
                <Route index element={<Claims />} />
                <Route path="add-claim" element={<AddClaim />} />
                <Route path="edit-claim" element={<EditClaim />} />
              </Route>
              <Route path="users" element={<Outlet />}>
                <Route index element={<UsersList />} />
                <Route path="user-detail" element={<Outlet />}>
                  <Route index element={<UserDetail />} />
                  <Route path="add-policy" element={<AddPolicy />} />
                  <Route path="edit-policy" element={<EditPolicy />} />
                  <Route path="add-document" element={<AddDocument />} />
                  <Route path="edit-document" element={<EditDocument />} />
                </Route>
              </Route>
              <Route path="messages" element={<Messages />} />
              <Route path="bg-image" element={<Outlet />}>
                <Route element={<Layout2 />}>
                  <Route index element={<BackgroundImg />} />
                  <Route path="app" element={<BackgroundImgApp />} />
                </Route>
              </Route>
              <Route path="add-img" element={<AddBackground />} />
              <Route path="videos" element={<Videos />} />
              <Route path="add-video" element={<AddVideo />} />
              <Route path="alerts" element={<AlertsComp />} />
              <Route path="add-alerts" element={<AddAlerts />} />
              <Route path="notifications" element={<Outlet />}>
                <Route index element={<NotificationList />} />
                <Route path="add-notification" element={<AddNotification />} />
              </Route>
              <Route path="policy-provider" element={<Outlet />}>
                <Route index element={<PolicyProvider />} />
                <Route path="add-provider" element={<AddProvider />} />
                <Route path="edit-provider" element={<EditProvider />} />
              </Route>
              <Route path="policies-list" element={<Outlet />}>
                <Route index element={<PolicyList />} />
                <Route path="add-policy" element={<AddNewPolicy />} />
              </Route>
              <Route path="all-users-policy" element={<Outlet />}>
                <Route index element={<AllUserList />} />
              </Route>
              <Route path="all-users-documents" element={<Outlet />}>
                <Route index element={<AllUsersDocuments />} />
              </Route>
              <Route path="employee-list" element={<Outlet />}>
                <Route index element={<EmployeeList />} />
                <Route path="add-employee" element={<AddEmployee />} />
                <Route path="edit-employee" element={<EditEmployee />} />
              </Route>
            </Route>
          )}
          {(user?.role == "Team lead" || user?.role == "Manager") && (
            <Route path="/" element={<Layout />}>
              <Route index element={<AllUserList />} />
              <Route path="all-users-documents" element={<Outlet />}>
                <Route index element={<AllUsersDocuments />} />
              </Route>
              <Route path="employee-list" element={<Outlet />}>
                <Route index element={<EmployeeList />} />
                <Route path="add-employee" element={<AddEmployee />} />
                <Route path="edit-employee" element={<EditEmployee />} />
              </Route>
              <Route path="messages" element={<Messages />} />
            </Route>
          )}
          {user?.role == "Team member" && (
            <Route path="/" element={<Layout />}>
              <Route index element={<AllUserList />} />
              <Route path="all-users-documents" element={<Outlet />}>
                <Route index element={<AllUsersDocuments />} />
              </Route>
              <Route path="messages" element={<Messages />} />
            </Route>
          )}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <CustomPopup
        isOpen={sessionExpire}
        onClose={() => {
          setSessionExpire(false);
          localStorage.clear();
          window.sessionStorage.clear();
          sessionStorage.clear();
          window.location.reload();
        }}
        title={"Alert"}
        mess={"Your session is expired please Login"}
        single_button={true}
        onClick={() => {
          setSessionExpire(false);
          window.sessionStorage.clear();
          localStorage.clear();
          // localStorage.removeItem("user");
          window.location.reload();
        }}
        b_name={"OK"}
      />
    </>
  );
}
function PageNotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Heading fontSize={"6xl"} color={"#ffbf00"}>
          404
        </Heading>
        <Heading fontSize={"2xl"} paddingBlock={2}>
          Page Not Found
        </Heading>
        <Text>Oops! You seem to be lost.</Text>
        <Text>
          Go to
          <Link
            to="/"
            style={{
              textDecorationLine: "underline",
              padding: 5,
            }}
          >
            {" "}
            Home{" "}
          </Link>
          page
        </Text>
      </div>
    </div>
  );
}
export default App;
