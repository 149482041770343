import { Card, Center, Container, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import HeadingText from "../../components/HeadingText";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addUpdateClaim } from "../../utils/apis";
import { BiArrowBack } from "react-icons/bi";
import { ID, isValidEmail } from "../../utils/utils";
import store from "../../redux/store";
import { claim_action } from "../../redux/slice/claimSlice";

const AddClaim = () => {
  const [isLarger] = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [claim_data, setClaim_data] = useState({});
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);

  const validation = () => {
    if (
      !claim_data?.ins_com ||
      !claim_data.contact ||
      !claim_data.logo ||
      // ||!claim_data.health_img ||
      // !claim_data.motor_img ||
      // !claim_data.health_url ||
      // !claim_data.motor_url ||
      (claim_data?.email && !isValidEmail.test(claim_data.email))
    ) {
      setClaim_data({
        ...claim_data,
        ins_comval: !claim_data?.ins_com ? true : false,
        ins_commess: !claim_data?.ins_com
          ? "Insurance Company is required"
          : "",
        contactval: !claim_data.contact ? true : false,
        contactmess: !claim_data.contact ? "Contact is required" : "",
        logoval: !claim_data.logo ? true : false,
        logomess: !claim_data.logo ? "Logo is required" : "",
        // health_imgval: !claim_data.health_img ? true : false,
        // health_imgmess: !claim_data.health_img
        //   ? "Health Insurance document is required"
        //   : "",
        // motor_imgval: !claim_data.motor_img ? true : false,
        // motor_imgmess: !claim_data.motor_img
        //   ? "Motor Insurance document Url is required"
        //   : "",
        // health_urlval: !claim_data.health_url ? true : false,
        // health_urlmess: !claim_data.health_url
        //   ? "Health Insurance Url is required"
        //   : "",
        // motor_urlval: !claim_data.motor_url ? true : false,
        // motor_urlmess: !claim_data.motor_url
        //   ? "Motor Insurance Url is required"
        //   : "",
        emailval:
          claim_data?.email && !isValidEmail.test(claim_data.email)
            ? true
            : false,
        emailmess:
          claim_data?.email && !isValidEmail.test(claim_data.email)
            ? "Invalid Email address"
            : "",
      });
      return;
    } else {
      add_claim();
    }
  };

  const add_claim = () => {
    const body = new FormData();
    body.append("action", "create");
    body.append("inscomp", claim_data.ins_com);
    body.append("contact", claim_data.contact);
    body.append("logo", claim_data.logo);
    body.append("email", claim_data.email ? claim_data.email : "");
    body.append(
      "healthurl",
      claim_data.health_url ? claim_data.health_url : ""
    );
    body.append("motorurl", claim_data.motor_url ? claim_data.motor_url : "");
    claim_data.health_img &&
      body.append(
        "healthfile",
        claim_data.health_img ? claim_data.health_img : ""
      );
    claim_data.motor_img &&
      body.append(
        "motorfile",
        claim_data.motor_img ? claim_data.motor_img : ""
      );
    body.append("userid", user.userid);
    // store.dispatch(claim_action(body))
    addUpdateClaim(body, setLoading).then((v) => v.success && navigate(-1));
  };
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        validation();
      }
    },
    [claim_data]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <HeadingText title={"Add Claim"} />
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Card bg={"#fff"} mt={2} p={5}>
        <CustomInput
          label1={"Insurance Company*"}
          placeholder1={"Enter Insurance Company"}
          value1={claim_data.ins_com}
          onChange1={(e) =>
            setClaim_data({
              ...claim_data,
              ins_com: e.target.value,
              ins_comval: false,
              ins_commess: "",
            })
          }
          errorBorder1={claim_data.ins_comval}
          error1={claim_data.ins_commess}
          label2={"Contact*"}
          placeholder2={"Enter Contact"}
          value2={claim_data.contact}
          onChange2={(e) => {
            setClaim_data({
              ...claim_data,
              contact: e.target.value.replace(/\D/g, ""),
              contactval: false,
              contactmess: "",
            });
          }}
          errorBorder2={claim_data.contactval}
          error2={claim_data.contactmess}
        />

        <Flex gap={3} flexDirection={isLarger ? "row" : "column"}>
          <CustomInput
            label1={"Email"}
            placeholder1={"Enter Email"}
            value1={claim_data.email}
            onChange1={(e) =>
              setClaim_data({
                ...claim_data,
                email: e.target.value,
                emailval: false,
                emailmess: "",
              })
            }
            onlyone
            errorBorder1={claim_data.emailval}
            error1={claim_data.emailmess}
          />
          <SelectImage
            mt={2}
            label={"Logo*"}
            filename={claim_data.logo?.name}
            onChange={(e) =>
              setClaim_data({
                ...claim_data,
                logo: e.target.files[0],
                logoval: false,
                logomess: "",
              })
            }
            errorBorder={claim_data.logoval}
            error={claim_data.logomess}
          />
        </Flex>
        <Flex gap={3} flexDirection={isLarger ? "row" : "column"}>
          <SelectImage
            mt={2}
            label={"Health Insurance document"}
            filename={claim_data.health_img?.name}
            onChange={(e) =>
              setClaim_data({
                ...claim_data,
                health_img: e.target.files[0],
                health_imgval: false,
                health_imgmess: "",
              })
            }
            errorBorder={claim_data.health_imgval}
            error={claim_data.health_imgmess}
          />
          <CustomInput
            label1={"Health Insurance Url"}
            placeholder1={"Enter Health Insurance Url"}
            value1={claim_data.health_url}
            onChange1={(e) =>
              setClaim_data({
                ...claim_data,
                health_url: e.target.value,
                health_urlval: false,
                health_urlmess: "",
              })
            }
            onlyone
            errorBorder1={claim_data.health_urlval}
            error1={claim_data.health_urlmess}
          />
        </Flex>
        <Flex gap={3} flexDirection={isLarger ? "row" : "column"}>
          <SelectImage
            mt={2}
            label={"Motor Insurance document"}
            filename={claim_data.motor_img?.name}
            onChange={(e) =>
              setClaim_data({
                ...claim_data,
                motor_img: e.target.files[0],
                motor_imgval: false,
                motor_imgmess: "",
              })
            }
            errorBorder={claim_data.motor_imgval}
            error={claim_data.motor_imgmess}
          />
          <CustomInput
            label1={"Motor Insurance Url"}
            placeholder1={"Enter Motor Insurance Url"}
            value1={claim_data.motor_url}
            onChange1={(e) =>
              setClaim_data({
                ...claim_data,
                motor_url: e.target.value,
                motor_urlval: false,
                motor_urlmess: "",
              })
            }
            onlyone
            errorBorder1={claim_data.motor_urlval}
            error1={claim_data.motor_urlmess}
          />
        </Flex>

        <Center mt={3}>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => validation()}
            // onClick={() => add_claim()}
          />
        </Center>
      </Card>
    </Container>
  );
};

export default AddClaim;
