import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Select,
  Spacer,
  Td,
  Text,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TableView from "../../components/TableView";
import store from "../../redux/store";
import Loader from "../../components/Loader";
import { getSearchUsers, getUsers } from "../../redux/slice/usersSlice";
import TableHeader from "../../components/TableHeader";
import { exportPDF } from "../../utils/utils";

const UsersList = () => {
  const [initialValue, setInitialValue] = useState(1);
  const navigate = useNavigate();
  const { users_list, u_loading, total_users, total_users_list } = useSelector(
    (state) => state.users
  );
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const [value, setValue] = useState("All");
  const [search, setSearch] = useState("");
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  useEffect(() => {
    store.dispatch(getUsers({ id: user.userid, rows: rows, page: page }));
  }, []);

  const headers = [
    { label: "S.No.", key: "no" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Platform", key: "platform" },
    { label: "Policies", key: "policies" },
    { label: "Document", key: "document" },
  ];
  const data = total_users_list.map((elt, i) => ({
    no: i + 1,
    name: elt.Name,
    email: elt.Email,
    mobile: elt.mobile,
    platform: elt.platform,
    policies: elt.totalpolicies,
    document: elt.totaldocument,
  }));

  const table_data = total_users_list.map((elt, i) => [
    i + 1,
    elt.Name,
    elt.Email,
    elt.mobile,
    elt.platform,
    elt.totalpolicies,
    elt.totaldocument,
  ]);

  const export_pdf = () => {
    exportPDF({
      table_data: table_data,
      table_headers: [
        "S.No.",
        "Name",
        "Email",
        "Mobile",
        "Platform",
        "Policies",
        "Document",
      ],

      fileName: "user-list",
      tableName: "Insugo User List",
    });
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Heading fontSize={"xl"}>Users List</Heading>
      <Spacer h={3} />
      <Flex gap={2} align={"center"} mb={1}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Users:
        </Text>
        <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
          {total_users}
        </Text>
      </Flex>
      <TableHeader
        date_range={
          value == "Status" && (
            <Select
              w={"80"}
              size={"md"}
              borderRadius={"md"}
              value={search}
              placeholder="Select ..."
              onChange={(v) => {
                setpage(1);
                v.target.value == ""
                  ? store.dispatch(
                      getUsers({
                        page: 1,
                        rows: rows,
                      })
                    )
                  : store.dispatch(
                      getSearchUsers({
                        page: 1,
                        rows: rows,
                        value: value,
                        v: v.target.value,
                      })
                    );

                setSearch(v.target.value);
              }}
            >
              <option value="0">Active</option>
              <option value="1">Deleted</option>
              <option value="2">Blocked</option>
            </Select>
          )
        }
        CSVdata={data}
        headers={headers}
        filename={"user-list.csv"}
        onPdfClick={() => export_pdf()}
        data={["All", "Name", "Email", "Mobile", "Status"]}
        onChange={(v) => {
          store.dispatch(
            getUsers({
              page: 1,
              rows: rows,
            })
          );
          setSearch("");
          setValue(v.target.value);
        }}
        value={search}
        onSerchChange={(v) => {
          setpage(1);
          v.target.value.length <= 2 &&
            store.dispatch(
              getUsers({
                page: 1,
                rows: rows,
              })
            );

          v.target.value.length > 2 &&
            store.dispatch(
              getSearchUsers({
                page: 1,
                rows: rows,
                value: value.length == 0 ? "All" : value,
                v: v.target.value,
              })
            );

          setSearch(v.target.value);
        }}
      />

      {u_loading && users_list.length === 0 ? (
        <Loader />
      ) : users_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "Name",
            "Email",
            "mobile",
            "platform",
            "policies",
            "document",
          ]}
          body={users_list?.map((v, i) => (
            <Tr key={i}>
              <Td>{i + initialValue}</Td>
              <Td display={"flex"} gap={2} alignItems={"center"}>
                <Tooltip
                  label={
                    v.status == 0
                      ? "Active"
                      : v.status == 1
                      ? "Deleted"
                      : "Blocked"
                  }
                  hasArrow
                >
                  <div
                    style={{
                      height: 8,
                      width: 8,
                      backgroundColor:
                        v.status == 0
                          ? "green"
                          : v.status == 1
                          ? "red"
                          : "orange",
                      borderRadius: 10,
                    }}
                  ></div>
                </Tooltip>
                <Link onClick={() => navigate("user-detail", { state: v.id })}>
                  {v.Name}
                </Link>
              </Td>
              <Td>{v.Email}</Td>
              <Td>{v.mobile}</Td>
              <Td>{v.platform}</Td>
              <Td>{v.totalpolicies}</Td>
              <Td>{v.totaldocument}</Td>
            </Tr>
          ))}
          total_value={total_users}
          pagination
          initialValue={initialValue}
          onChange={(v) => {
            setRows(v.target.value);
            store.dispatch(getUsers({ page: 1, rows: v.target.value }));
            setInitialValue(1);
            setpage(1);
          }}
          rows={rows}
          page={page}
          onPrevious={() => {
            initialValue == 1 ||
              setInitialValue(Number(initialValue) - Number(rows));
            page == 1 || setpage(page - 1);
            page == 1 ||
              store.dispatch(getUsers({ page: page - 1, rows: rows }));
          }}
          onNext={() => {
            rows * page >= total_users ||
              setInitialValue(Number(initialValue) + Number(rows));
            rows * page >= total_users || setpage(page + 1);
            rows * page >= total_users ||
              store.dispatch(getUsers({ page: page + 1, rows: rows }));
          }}
        />
      )}
    </Container>
  );
};

export default UsersList;
