import {
  Button,
  Card,
  Center,
  Container,
  Flex,
  Heading,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import {
  get_provider_list,
  policy_action,
  provider_action,
} from "../../utils/apis";
import { toast } from "react-toastify";

const AddNewPolicy = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const Add_pro = () => {
    if (name.length == 0) {
      toast.info("Policy name cannot be empty");
      return;
    }
    const body = new FormData();
    body.append("action", "create");
    body.append("name", name);
    policy_action(body, setLoading).then(() => {
      setName("");
      navigate(-1);
    });
  };

  return (
    <Container h={"full"} w={"full"} maxW={"container.lg"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Add Policy</Heading>
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => {
            navigate(-1);
          }}
        />
      </Flex>
      <Center>
        <Card maxW={"lg"} flex={1} p={4}>
          <CustomInput
            autoFocus1={true}
            label1={"Policy Name*"}
            onlyone
            placeholder1={"Enter Policy Name"}
            value1={name}
            onChange1={(e) => setName(e.target.value)}
          />
          <Center mt={3}>
            <CustomButton
              title={"Submit"}
              loading={loading}
              onClick={Add_pro}
            />
          </Center>
        </Card>
      </Center>
    </Container>
  );
};

export default AddNewPolicy;
