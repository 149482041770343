import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";
import store from "../store";

const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);
export const getPolicylist = createAsyncThunk("policy", async () => {
  const body = new FormData();
  body.append("action", "list");
  const response = await fetch(base_url + "api/policy-list.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();

  return res;
});

export const getAllPolicy = createAsyncThunk("allpolicy", async (data) => {
  const body = new FormData();
  body.append("action", "list");
  body.append("page", data.page);
  body.append("limit", data.rows);
  body.append("userid", user.userid);

  const response = await fetch(base_url + "api/user-policies.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  store.dispatch(getAllPolicy_list(res.totalcount));
  return res;
});
export const getAllPolicy_list = createAsyncThunk(
  "allpolicy_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "list");
    body.append("page", 1);
    body.append("limit", data);
    body.append("userid", user.userid);

    const response = await fetch(base_url + "api/user-policies.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    return res;
  }
);
export const getSearchAllPolicy_list = createAsyncThunk(
  "search_policy_list",
  async (data) => {
    const body = new FormData();
    body.append("action", "filter");
    body.append("page", data.page);
    body.append("limit", data.rows);
    body.append("userid", user.userid);
    body.append(
      "filter",
      data.value == "All" ? data.value : data.value.toLowerCase()
    );
    body.append("filterVal", data.v);

    const response = await fetch(base_url + "api/user-policies.php", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    return res;
  }
);

const policylistSlice = createSlice({
  name: "policy",
  initialState: {
    policies_list: [],
    p_loading: false,
    all_loading: false,
    all_users_policy: [],
    total_policies_list: [],
    total_policies: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(getPolicylist.pending, (state, action) => {
      state.p_loading = true;
    });
    builder.addCase(getPolicylist.rejected, (state, action) => {
      state.p_loading = false;
    });
    builder.addCase(getPolicylist.fulfilled, (state, action) => {
      const data = action.payload;
      state.p_loading = false;
      if (data.success) {
        state.policies_list = data.message;
      } else {
        state.policies_list = [];
      }
    });

    builder.addCase(getAllPolicy.pending, (state, action) => {
      state.all_loading = true;
    });
    builder.addCase(getAllPolicy.rejected, (state, action) => {
      state.all_loading = false;
    });
    builder.addCase(getAllPolicy.fulfilled, (state, action) => {
      const data = action.payload;
      state.all_loading = false;
      state.total_policies = data.totalcount;
      if (data.success) {
        state.all_users_policy =
          data.message == "No Data available" ? [] : data.message;
      } else {
        state.all_users_policy = [];
      }
    });

    builder.addCase(getSearchAllPolicy_list.pending, (state, action) => {
      state.all_loading = true;
    });
    builder.addCase(getSearchAllPolicy_list.rejected, (state, action) => {
      state.all_loading = false;
    });
    builder.addCase(getSearchAllPolicy_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.all_loading = false;
      state.total_policies = data.totalcount;
      if (data.success) {
        state.all_users_policy =
          data.message == "No Data available" ? [] : data.message;
      } else {
        state.all_users_policy = [];
      }
    });

    builder.addCase(getAllPolicy_list.fulfilled, (state, action) => {
      const data = action.payload;
      // state.all_loading = false;
      if (data.success) {
        state.total_policies_list =
          data.message == "No Data available" ? [] : data.message;
      } else {
        state.total_policies_list = [];
      }
    });
  },
});

export default policylistSlice.reducer;
