import {
  Card,
  Center,
  Checkbox,
  Container,
  Flex,
  FormLabel,
  Heading,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomInput from "../../components/CustomInput";
import Select, { StylesConfig } from "react-select";
import { useSelector } from "react-redux";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { sendNotification } from "../../utils/apis";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import store from "../../redux/store";
import { getNotificationlist } from "../../redux/slice/notificationSlice";
import { BiArrowBack } from "react-icons/bi";
import { ID } from "../../utils/utils";
import { FiFilter } from "react-icons/fi";
import { getUsers } from "../../redux/slice/usersSlice";

const AddNotification = () => {
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const navigate = useNavigate();
  const { users_list, total_users_list } = useSelector((state) => state.users);
  const [data, setData] = useState({});
  const [scheduled, setScheduled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter_val, setFilter_val] = useState({ label: "All", value: "All" });
  const list = [];
  for (let i = 0; i < total_users_list.length; i++) {
    const element = {
      label: total_users_list[i].Name,
      value:
        total_users_list[i].id +
        "," +
        total_users_list[i].Email +
        "," +
        total_users_list[i].mobile,
    };
    list.push(element);
  }
  const all_user = [{ label: "All Users", value: "all" }];
  const all_user_list = all_user.concat(list);

  const validation = () => {
    if (
      !data.title ||
      !data.message ||
      !data?.users ||
      Object.keys(data?.users).length == 0
    ) {
      setData({
        ...data,
        titleval: !data.title ? true : false,
        titlemess: !data.title ? "Title is required" : "",
        messageval: !data.message ? true : false,
        messagemess: !data.message ? "Message is required" : "",
        usersval:
          !data?.users || Object.keys(data?.users).length == 0 ? true : false,
        usersmess:
          !data?.users || Object.keys(data?.users).length == 0
            ? "User is required"
            : "",
      });
    } else {
      send_notification();
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #f00",
    }),
  };
  const send_notification = () => {
    if (!data.title || !data.message) {
      toast.info("title and message are required");
      return;
    }
    if (!data?.users) {
      toast.info("user are required");
      return;
    }
    const body = new FormData();
    body.append("userid", ID);
    body.append("mode", data?.users?.find((v) => v.value == "all") ? 2 : 1);
    body.append("title", data.title);
    body.append("message", data.message);
    body.append(
      "id",
      data?.users?.find((v) => v.value == "all")
        ? ""
        : data?.users
            ?.map((v) => v.value.split(","))
            .map((v) => v[0])
            .toString()
      // : data?.users.map((v) => v.value).toString()
    );
    scheduled &&
      body.append("date", moment(data?.date).format("YYYY-MM-DD hh:mm:ss"));
    {
      data?.img && body.append("image", data?.img);
    }
    sendNotification(body, setLoading).then((v) => {
      v?.success && navigate(-1);
      store.dispatch(getNotificationlist());
    });
  };
  const customFilter = (option, searchText) => {
    if (
      option?.data?.label?.toLowerCase().includes(searchText?.toLowerCase()) ||
      option?.data?.value?.toLowerCase().includes(searchText?.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    store.dispatch(getUsers({ id: user.userid, rows: 10, page: 1 }));
  }, []);
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Add Notification</Heading>
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Spacer h={3} />
      <Card px={3} pb={3}>
        <CustomInput
          label1={"Title*"}
          placeholder1={"Enter Title"}
          value1={data.title}
          onChange1={(e) =>
            setData({
              ...data,
              title: e.target.value,
              titleval: false,
              titlemess: "",
            })
          }
          error1={data.titlemess}
          errorBorder1={data.titleval}
          label2={"Message*"}
          placeholder2={"Enter Message"}
          value2={data.message}
          onChange2={(e) =>
            setData({
              ...data,
              message: e.target.value,
              messagemess: "",
              messageval: false,
            })
          }
          area2
          error2={data.messagemess}
          errorBorder2={data.messageval}
        />
        <Flex gap={3} mt={3}>
          <div style={{ flex: 1 }}>
            <FormLabel marginBottom={2} marginLeft={2}>
              Select Users*
            </FormLabel>
            <Select
              placeholder={"Search and Select Users"}
              options={
                data?.users?.find((v) => v.value == "all")
                  ? all_user
                  : all_user_list
              }
              isMulti={true}
              value={data.users}
              isSearchable
              // filterOption={customFilter}
              onChange={(v) => {
                setData({ ...data, users: v, usersmess: "", usersval: false });
              }}
              styles={data.usersval && customStyles}
            />
            {
              <Text fontSize={"sm"} color={"#f00"}>
                {data.usersmess}
              </Text>
            }
          </div>
          <Stack flex={1}></Stack>
        </Flex>

        <Flex gap={3} mt={3}>
          <SelectImage
            label={"Image (Optional)"}
            // value={data.users}
            url={data?.img}
            filename={data?.img?.name}
            onChange={(e) => setData({ ...data, img: e.target.files[0] })}
          />
          <Stack flex={1} />
        </Flex>
        <Checkbox
          alignSelf={"self-start"}
          isChecked={scheduled}
          onChange={(e) => setScheduled(e.target.checked)}
          my={3}
          fontSize={"xs"}
        >
          Scheduled Notification
        </Checkbox>
        {scheduled && (
          <CustomInput
            label1={"Scheduled Notification"}
            type1={"datetime-local"}
            value1={data?.date}
            min1={moment(new Date()).format("YYYY-MM-DD hh:mm:ss")}
            onChange1={(e) => {
              setData({
                ...data,
                date: e.target.value,
              });
            }}
            single
          />
        )}
        <Center mt={3}>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => validation()}
            // onClick={() => send_notification()}
          />
        </Center>
      </Card>
    </Container>
  );
};

export default AddNotification;
