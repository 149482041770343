import { Card, Center, Container, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import HeadingText from "../../components/HeadingText";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addUpdateClaim,
  add_background_image,
  video_image,
} from "../../utils/apis";
import { BiArrowBack } from "react-icons/bi";
import { ID, isValidEmail } from "../../utils/utils";
import store from "../../redux/store";
import { claim_action } from "../../redux/slice/claimSlice";

const AddVideo = () => {
  const [isLarger] = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Obj, setObj] = useState({});
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);

  const { state } = useLocation();

  const validation = () => {
    if (!Obj?.title || !Obj.file || !Obj?.img_file) {
      setObj({
        ...Obj,
        titleval: !Obj?.title ? true : false,
        titlemess: !Obj?.title ? "Title is required" : "",

        fileval: !Obj?.file?.name ? true : false,
        filemess: !Obj?.file?.name ? "Video is required" : "",
        img_fileval: !Obj?.img_file?.name ? true : false,
        img_filemess: !Obj?.img_file?.name ? "Video Thumbnail is required" : "",
      });
      return;
    } else {
      add_video();
    }
  };

  const add_video = () => {
    const body = new FormData();
    body.append("action", "upload_video");
    body.append("title", Obj.title);
    body.append("video", Obj.file);
    body.append("image", Obj.img_file);
    video_image(body, setLoading, state).then((v) => v.success && navigate(-1));
  };
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <HeadingText title={"Add Video"} />
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Card bg={"#fff"} mt={2} p={5}>
        <Flex gap={3} flexDirection={isLarger ? "row" : "column"}>
          <CustomInput
            label1={"Title"}
            placeholder1={"Enter Title"}
            value1={Obj.title}
            onChange1={(e) =>
              setObj({
                ...Obj,
                title: e.target.value,
                titleval: false,
                titlemess: "",
              })
            }
            onlyone
            errorBorder1={Obj.titleval}
            error1={Obj.titlemess}
          />
          <SelectImage
            mt={2}
            label={"Video* (Resolution : 1200x600)"}
            filename={Obj.file?.name}
            onChange={(e) =>
              setObj({
                ...Obj,
                file: e.target.files[0],
                fileval: false,
                filemess: "",
              })
            }
            // doctype={"image/png, image/jpg, image/jpeg "}
            errorBorder={Obj.fileval}
            doctype={
              "video/mp4, video/avi, video/mkv, video/mov, video/webm, video/ogg"
            }
            error={Obj.filemess}
          />
        </Flex>
        <Flex w={"full"}>
          <Flex flex={1}>
            <SelectImage
              mt={2}
              label={"Thumbnail Image*"}
              filename={Obj.img_file?.name}
              onChange={(e) =>
                setObj({
                  ...Obj,
                  img_file: e.target.files[0],
                  img_fileval: false,
                  img_filemess: "",
                })
              }
              doctype={"image/png, image/jpg, image/jpeg "}
              errorBorder={Obj.img_fileval}
              error={Obj.img_filemess}
            />
          </Flex>
          <Flex flex={1}></Flex>
        </Flex>
        <Center mt={3}>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => validation()}
            // onClick={() => add_video()}
          />
        </Center>
      </Card>
    </Container>
  );
};

export default AddVideo;
