import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

export const getDash_data = createAsyncThunk("dashData", async () => {
  const body = new FormData();
  body.append("action", "data");

  const response = await fetch(base_url + "api/analytics.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});
export const getDash_list = createAsyncThunk("dashlist", async (data) => {
  const body = new FormData();
  body.append("action", "list");
  body.append("sdate", data.startDate);
  body.append("edate", data.endDate);

  const response = await fetch(base_url + "api/analytics.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();

  return res;
});

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboard_data: {},
    dashboard_list: {},
    d_loading: false,
    l_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getDash_data.pending, (state, action) => {
      state.d_loading = true;
    });
    builder.addCase(getDash_data.rejected, (state, action) => {
      state.d_loading = false;
    });

    builder.addCase(getDash_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.d_loading = false;
      if (data.success) {
        state.dashboard_data = data;
      } else {
        state.dashboard_data = {};
      }
    });

    builder.addCase(getDash_list.pending, (state, action) => {
      state.l_loading = true;
    });
    builder.addCase(getDash_list.rejected, (state, action) => {
      state.l_loading = false;
    });

    builder.addCase(getDash_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.l_loading = false;
      if (data.success) {
        state.dashboard_list = data.message;
      } else {
        state.dashboard_list = {};
      }
    });
  },
});

export default dashboardSlice.reducer;
