import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Spacer,
  Spinner,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useSelector } from "react-redux";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import CustomPopup from "../../components/CustomPopup";
import store from "../../redux/store";
import { deleteClaim, getClaims } from "../../redux/slice/claimSlice";
import { useNavigate } from "react-router-dom";
import ImageModal from "../../components/ImageModal";
import CustomButton from "../../components/CustomButton";
import Loader from "../../components/Loader";

const Claims = () => {
  const navigate = useNavigate();
  const { claim_list, delete_loading, c_loading } = useSelector(
    (state) => state.claims
  );
  const [id, setId] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();
  const [url, setUrl] = useState("");
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  useEffect(() => {
    store.dispatch(getClaims());
  }, []);
  const onDelete = () => {
    store.dispatch(deleteClaim(id));
  };
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        onDelete();
      }
    },
    [id]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Claim List</Heading>
        <CustomButton
          title={"Add Claim"}
          onClick={() => navigate("add-claim")}
        />
      </Flex>
      <Spacer h={3} />
      {c_loading && claim_list.length === 0 ? (
        <Loader />
      ) : claim_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "Insurance Company",
            "Email",
            "contact",
            "health",
            "motor",
            "image",
            "Actions",
          ]}
          body={claim_list.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + 1}</Td>
              <Td textAlign={"center"} cursor={"pointer"}>
                {v.insu_company}
              </Td>
              <Td textAlign={"center"}>{v.email}</Td>
              <Td textAlign={"center"}>{v.contact}</Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  {v.healthurl.length != 0 && (
                    <Link
                      href={v.healthurl}
                      isExternal
                      fontSize={"md"}
                      fontWeight={"semibold"}
                      color={"#0000ff"}
                    >
                      Link
                    </Link>
                  )}{" "}
                  {v.healthurl.length != 0 && v.health.length != 0 && "|"}
                  {v.health.length == 0 || (
                    <Text
                      fontSize={"md"}
                      fontWeight={"semibold"}
                      color={"#0000ff"}
                      cursor={"pointer"}
                      onClick={() => {
                        setUrl(v.health);
                        onViewOpen();
                      }}
                    >
                      File
                    </Text>
                  )}
                </Flex>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  {v.motorurl.length != 0 && (
                    <Link
                      href={v.motorurl}
                      isExternal
                      fontSize={"md"}
                      fontWeight={"semibold"}
                      color={"#0000ff"}
                    >
                      Link
                    </Link>
                  )}{" "}
                  {v.motorurl.length != 0 && v.motor.length != 0 && "|"}
                  {v.motor.length != 0 && (
                    <Text
                      fontSize={"md"}
                      fontWeight={"semibold"}
                      color={"#0000ff"}
                      cursor={"pointer"}
                      onClick={() => {
                        setUrl(v.motor);
                        onViewOpen();
                      }}
                    >
                      File
                    </Text>
                  )}
                </Flex>
              </Td>
              <Td textAlign={"center"}>
                <Center>
                  <Image src={v.image} w={"16"} objectFit={"contain"} />
                </Center>
              </Td>
              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiEdit
                    size={20}
                    cursor={"pointer"}
                    onClick={() => navigate("edit-claim", { state: v })}
                  />

                  {delete_loading && id == v.id ? (
                    <Spinner
                      thickness="2px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="md"
                    />
                  ) : (
                    <FiTrash2
                      size={20}
                      cursor={"pointer"}
                      onClick={() => {
                        setId(v.id);
                        onOpen();
                      }}
                    />
                  )}
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? you want to delete claim"}
        onClick={() => {
          onDelete();
          onClose();
        }}
      />
      <ImageModal isOpen={isViewOpen} onClose={onViewClose} url={url} />
    </Container>
  );
};

export default Claims;
