import {
  Card,
  Center,
  Checkbox,
  Container,
  Flex,
  FormLabel,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { provider_action } from "../../utils/apis";
import { toast } from "react-toastify";
import { BiArrowBack } from "react-icons/bi";

const AddProvider = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState([
    { val: 0, label: "Health" },
    { val: 0, label: "Motor" },
    { val: 0, label: "Life" },
    { val: 0, label: "Others" },
  ]);
  const Add_pro = () => {
    if (!name) {
      toast.info("Enter provider name");
      return;
    }
    if (
      type[0].val == 0 &&
      type[1].val == 0 &&
      type[2].val == 0 &&
      type[3].val == 0
    ) {
      toast.info("Select policy type");
      return;
    }
    const body = new FormData();
    body.append("action", "create");
    body.append("provider", name);
    body.append("instype", type.map((v) => v.val).toString());
    provider_action(body, setLoading).then(() => {
      setName("");
      navigate(-1);
    });
  };

  return (
    <Container h={"full"} w={"full"} maxW={"container.lg"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Add Provider</Heading>
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        ></CustomButton>
      </Flex>
      <Center>
        <Card maxW={"lg"} flex={1} p={4}>
          <CustomInput
            label1={"Policy Provider Name*"}
            onlyone
            placeholder1={"Enter Policy Provider Name"}
            value1={name}
            onChange1={(e) => setName(e.target.value)}
          />
          <FormLabel marginTop={4} marginLeft={2}>
            Policy Type*
          </FormLabel>
          <Flex gap={10}>
            {type.map((v, i) => (
              <Checkbox
                key={i}
                alignSelf={"self-start"}
                isChecked={v.val == 1 ? true : false}
                onChange={(e) =>
                  setType((pre) =>
                    pre.map((el) =>
                      el.label == v.label
                        ? { ...el, val: v.val == 1 ? 0 : 1 }
                        : el
                    )
                  )
                }
                fontSize={"xs"}
              >
                {v.label}
              </Checkbox>
            ))}
          </Flex>

          <Center mt={4}>
            <CustomButton
              title={"Submit"}
              loading={loading}
              onClick={Add_pro}
            />
          </Center>
        </Card>
      </Center>
    </Container>
  );
};

export default AddProvider;
