import {
  Card,
  Center,
  Container,
  Flex,
  FormLabel,
  Heading,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";
import Select from "react-select";
import CustomButton from "../../components/CustomButton";
import { toast } from "react-toastify";
import { addUserPolicy, get_provider_list } from "../../utils/apis";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { ID } from "../../utils/utils";
import { useSelector } from "react-redux";

const options = [
  { value: "Motor", label: "Motor Insurance" },
  { value: "Health", label: "Health Insurance" },
  { value: "Life", label: "Life Insurance" },
  { value: "Other", label: "Other Insurance" },
];

const EditPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [p_data, setP_data] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const { provider_list } = useSelector((state) => state.providers);
  const { policies_list } = useSelector((state) => state.policies);
  const [provide_list, setProvide_list] = useState([]);
  const pro_list = [];
  for (let i = 0; i < provider_list.length; i++) {
    const element = {
      value: provider_list[i].provider,
      label: provider_list[i].provider,
    };
    pro_list.push(element);
  }
  const other_list = [];
  for (let i = 0; i < policies_list.length; i++) {
    const element = {
      value: policies_list[i].policyname,
      label: policies_list[i].policyname,
    };
    other_list.push(element);
  }

  const add_policy = () => {
    if (
      !p_data.name ||
      !p_data.company ||
      !p_data.v_number ||
      !p_data.p_number ||
      !p_data.e_date
    ) {
      toast.info("Please fill all fields");
      return;
    }
    const body = new FormData();
    body.append("action", "update");
    body.append("userid", location.state.customerid);
    body.append("addedby", ID);
    body.append("id", location.state.id);
    body.append(
      "instype",
      selectedOption.label == "Other Insurance"
        ? selectedOption1.value
        : selectedOption.value
    );
    body.append("holname", p_data.name);
    body.append("polcompany", p_data.company.value);
    body.append("polplan", p_data.v_number);
    body.append("polnum", p_data.p_number);
    body.append("expiry", p_data.e_date);
    p_data?.img && body.append("policyfile", p_data?.img);
    addUserPolicy(body, setLoading).then((v) => v.success && navigate(-1));
  };

  useEffect(() => {
    location.state.insurance_type === "Motor" ||
    location.state.insurance_type === "Health" ||
    location.state.insurance_type === "Life"
      ? setSelectedOption(
          options.filter((v) => v.value == location.state.insurance_type)[0]
        )
      : setSelectedOption(options.filter((v) => v.value === "Other")[0]);
    location.state.insurance_type === "Motor" ||
    location.state.insurance_type === "Health" ||
    location.state.insurance_type === "Life"
      ? setSelectedOption(
          options.filter((v) => v.value == location.state.insurance_type)[0]
        )
      : setSelectedOption1(
          other_list?.filter((v) => v.value == location.state.insurance_type)[0]
        );

    setP_data({
      ...p_data,
      name: location.state.name,
      company: pro_list?.filter(
        (v) => v.value == location.state.policy_company
      )[0],
      p_number: location.state.policy_number,
      v_number: location.state.policy_plan,
      e_date: location.state.expiry_date,
    });
  }, []);
  const provider_by_type = (type) => {
    get_provider_list(type, setLoading1).then((v) => {
      if (v.success) {
        const pro_list = [];
        for (let i = 0; i < v.message.length; i++) {
          const element = {
            value: v.message[i].provider,
            label: v.message[i].provider,
          };
          pro_list.push(element);
        }
        setProvide_list(pro_list);
      }
    });
  };
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        add_policy();
      }
    },
    [p_data, selectedOption, selectedOption1]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Edit Policy</Heading>
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Spacer h={3} />
      <Flex maxW={"xl"}>
        <div style={{ flex: 1 }}>
          <FormLabel marginBottom={2}>Select Policy Type*</FormLabel>
          <Select
            value={selectedOption}
            onChange={(v) => {
              setSelectedOption(v);
              setP_data({});
              setSelectedOption1(null);
              provider_by_type(v.value);
            }}
            options={options}
            placeholder={"Select policy..."}
          />
        </div>
      </Flex>

      {selectedOption == null || (
        <Card p={5} borderRadius={10} shadow={"base"} mt={3}>
          <Heading fontSize={"lg"}>{selectedOption?.label}</Heading>
          {selectedOption?.label === "Other Insurance" && (
            <Flex maxW={"xl"} mt={3} gap={2}>
              <div style={{ flex: 1 }}>
                <FormLabel marginBottom={2}>Select Other Policy*</FormLabel>
                <Select
                  defaultValue={selectedOption1}
                  onChange={setSelectedOption1}
                  options={other_list}
                  placeholder={"Select other policy..."}
                />
              </div>
              <Stack />
            </Flex>
          )}
          {(selectedOption1 == null &&
            selectedOption?.label === "Other Insurance") || (
            <Stack>
              <Flex gap={3} flexDirection={["column", "column", "row"]}>
                <Stack flex={1}>
                  <FormLabel marginBottom={0} mt={2}>
                    Select Policy Company*
                  </FormLabel>
                  <Select
                    placeholder={"Search and select policy company"}
                    value={p_data.company}
                    onChange={(v) => setP_data({ ...p_data, company: v })}
                    options={provide_list}
                  />
                </Stack>
                <SelectImage
                  doctype={".pdf"}
                  onChange={(e) =>
                    setP_data({ ...p_data, img: e.target.files[0] })
                  }
                  filename={p_data?.img?.name}
                  url={p_data?.img}
                  mt={2}
                  label={"Upload Insurance Copy*"}
                />
              </Flex>

              <CustomInput
                label1={"Policy Holder Name*"}
                placeholder1={"Enter Policy holder name"}
                value1={p_data.name}
                onChange1={(e) =>
                  setP_data({ ...p_data, name: e.target.value })
                }
                label2={
                  selectedOption?.label == "Motor Insurance"
                    ? "Vehicel Number*"
                    : "Policy Plan*"
                }
                placeholder2={
                  selectedOption?.label == "Motor Insurance"
                    ? "Enter Vehicel Number"
                    : "Enter Policy Plan"
                }
                value2={p_data.v_number}
                onChange2={(e) =>
                  setP_data({ ...p_data, v_number: e.target.value })
                }
              />
              <CustomInput
                label1={"Policy Number*"}
                placeholder1={"Enter Policy number"}
                value1={p_data.p_number}
                onChange1={(e) =>
                  setP_data({ ...p_data, p_number: e.target.value })
                }
                label2={"Expiry Date*"}
                placeholder2={"Enter Expiry Date"}
                min2={moment(new Date()).format("YYYY-MM-DD")}
                value2={p_data.e_date}
                onChange2={(e) => {
                  setP_data({ ...p_data, e_date: e.target.value });
                }}
                type2={"date"}
              />

              <Center mt={5}>
                <CustomButton
                  title={"Submit"}
                  onClick={() => add_policy()}
                  loading={loading}
                />
              </Center>
            </Stack>
          )}
        </Card>
      )}
    </Container>
  );
};

export default EditPolicy;
