import React, { useEffect, useState } from "react";
import {
  AspectRatio,
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Spacer,
  Td,
  Text,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import CustomButton from "../../components/CustomButton";
import { useSelector } from "react-redux";
import CustomPopup from "../../components/CustomPopup";
import { FiTrash2 } from "react-icons/fi";
import TableView from "../../components/TableView";
import Loader from "../../components/Loader";
import store from "../../redux/store";
import { delete_video, get_video } from "../../redux/slice/VideoSlice";
import { useNavigate } from "react-router-dom";
import ImageModal from "../../components/ImageModal";
import { delete_alerts, get_alerts } from "../../redux/slice/alertsSlice";

const AlertsComp = () => {
  const { alerts_list, alerts_loading, delete_loading } = useSelector(
    (state) => state.alerts
  );
  const [id, setId] = useState([]);
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isViewOpen,
    onClose: onViewClose,
    onOpen: onViewOpen,
  } = useDisclosure();

  useEffect(() => {
    store.dispatch(get_alerts());
  }, []);

  const onDelete = async () => {
    await store.dispatch(delete_alerts({ id: id }));
    await store.dispatch(get_alerts());
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Alerts List</Heading>
        <CustomButton
          title={"Add Alerts"}
          onClick={() => navigate("/add-alerts")}
        />
      </Flex>
      <Flex gap={2} align={"center"}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Alerts: {alerts_list?.length}
        </Text>
      </Flex>

      {alerts_loading && alerts_list.length === 0 ? (
        <Loader />
      ) : alerts_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Image src="no-data.jpg" h={"md"} objectFit={"contain"} />
        </Center>
      ) : (
        <TableView
          headData={["S.No.", "Alert Message", "Sent To", "Actions"]}
          body={alerts_list.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + 1}</Td>
              <Td textAlign={"center"}>{v?.Message}</Td>
              {/* <Td textAlign={"center"}>{v?.Name}</Td> */}
              {/* <Td textAlign={"center"} maxW={"300px"}>{v?.Customers?.map((val,i)=>`${val?.Name} ,`)              }</Td> */}
              <Td
                textAlign={"center"}
                maxW={"300px"}
                whiteSpace="normal" // Enables word wrapping
                wordBreak="break-word" // Breaks long words if necessary
              >
                {v?.Cus_typ == "all"
                  ? "All User"
                  : v?.Customers?.map(
                      (val, ind) => `${ind == 0 ? "" : ","} ${val?.Name} `
                    )}
              </Td>

              <Td textAlign={"center"}>
                <Flex gap={3} justify={"center"}>
                  <FiTrash2
                    size={20}
                    cursor={"pointer"}
                    onClick={() => {
                      onOpen();
                      setId(
                        v?.Cus_typ == "all"
                          ? [v?.Id]
                          : v?.Customers?.map((val, ind) => val?.id)
                      );
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}

      <CustomPopup
        isOpen={isOpen}
        onClose={onClose}
        title={"Delete"}
        mess={"Are you sure? you want to delete Background Images"}
        onClick={() => {
          onDelete();
          onClose();
        }}
      />
      <ImageModal
        vid={true}
        isOpen={isViewOpen}
        onClose={onViewClose}
        url={url}
      />
    </Container>
  );
};

export default AlertsComp;
