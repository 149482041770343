import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
import store from "../store";
import { toast } from "react-toastify";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const getClaims = createAsyncThunk("claims", async (id) => {
  const body = new FormData();
  body.append("action", "list");
  body.append("userid", user.userid);
  const response = await fetch(base_url + "api/claims.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});
export const deleteClaim = createAsyncThunk("delclaims", async (id) => {
  const body = new FormData();
  body.append("action", "delete");
  body.append("id", id);
  body.append("userid", user.userid);
  const response = await fetch(base_url + "api/claims.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  if (res.success) {
    toast.success(res.message);
    store.dispatch(getClaims());
    return res;
  } else {
    toast.error(res.message);
    return res;
  }
});
export const claim_action = createAsyncThunk("delclaims", async (body) => {
  const response = await fetch(base_url + "api/claims.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  if (res.success) {
    toast.success(res.message);
    store.dispatch(getClaims());
    return res;
  } else {
    toast.error(res.message);
    return res;
  }
});

const claimSlice = createSlice({
  name: "claims",
  initialState: {
    claim_list: [],
    c_loading: false,
    delete_loading: false,
    add_loading: false,
    update_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getClaims.pending, (state, action) => {
      state.c_loading = true;
    });
    builder.addCase(getClaims.rejected, (state, action) => {
      state.c_loading = false;
    });
    builder.addCase(deleteClaim.pending, (state, action) => {
      state.delete_loading = true;
    });
    builder.addCase(deleteClaim.rejected, (state, action) => {
      state.delete_loading = false;
    });
    builder.addCase(deleteClaim.fulfilled, (state, action) => {
      state.delete_loading = false;
      //   store.dispatch(getClaims());
    });

    builder.addCase(getClaims.fulfilled, (state, action) => {
      const data = action.payload;
      state.c_loading = false;
      if (data.success) {
        state.claim_list = data.message;
      } else {
        state.claim_list = [];
      }
    });
  },
});

export default claimSlice.reducer;
