import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";

export const getEmployee_list = createAsyncThunk("employee", async (id) => {
  const body = new FormData();
  body.append("action", "list");
  body.append("id", id);
  const response = await fetch(base_url + "api/employee.php", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employee_list: [],
    e_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployee_list.pending, (state, action) => {
      state.e_loading = true;
    });
    builder.addCase(getEmployee_list.rejected, (state, action) => {
      state.e_loading = false;
    });

    builder.addCase(getEmployee_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.e_loading = false;
      if (data.success) {
        state.employee_list = data.data;
      } else {
        state.employee_list = [];
      }
    });
  },
});

export default employeeSlice.reducer;
